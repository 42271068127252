import config from './constants'

export default class PaginationParameters implements IPaginationMetadata {
  constructor (
    public count: number = 0,
    public limit: number = config.pagination.defaultLimit,
    public skip: number = 0
  ) {}

  static build (dto: any = {}): PaginationParameters {
    return new PaginationParameters(
      parseInt(dto.count, 10) || 0,
      parseInt(dto.limit, 10) || config.pagination.defaultLimit,
      parseInt(dto.skip, 10) || 0
    )
  }
}
