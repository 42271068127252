import localeHelper from 'common/localeHelper'

export default function translateInnerText ($filter) {
  return {
    restrict: 'A',
    scope: {
      preventArabicTranslation: '<'
    },
    link: function ($scope, $element) {
      const translationKey = $element.text()

      $scope.$watch(
        () => localeHelper.getLocale(),
        () => {
          $element.text($filter('translate')(translationKey, !!$scope.preventArabicTranslation))
        }
      )
    }
  }
}
