import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Address from 'data/domain-objects/Address'
import AddressFormat from 'data/domain-objects/AddressFormat'
import { ACCEPT_LANG_HEADER } from 'common/agent'
import { generateLocaleData } from 'common/localeHelper'


const useCacheParams = {useCache: true}

export class AddressCollectionFactory {

  private addressFormats: AddressFormat[]

  constructor(
    private addressService: CrudService = new CrudService(endpoints.ADDRESS),
    private addressFieldsService: CrudService = new CrudService(endpoints.ADDRESS_FIELD_VALUES),
    private addressFormatsService: CrudService = new CrudService(endpoints.ADDRESS_FORMATS),
    private addressFormatService: CrudService = new CrudService(endpoints.ADDRESS_FORMAT),
    private consumerAddressService: CrudService = new CrudService(endpoints.CONSUMER_ADDRESS),
    private consumerAddressesService: CrudService = new CrudService(endpoints.CONSUMER_ADDRESSES)
  ) {}

  private getAddressFormatFromAddressDTO (addressDTO: any): Promise<any[]> {
    return this.getAddressFormat(addressDTO.addressFormatId)
      .then((addressFormatDTO: any) => [addressDTO, addressFormatDTO])
  }

  public getAddressDescriptor (id: string): Promise <any> {
    return this.addressService.get([id])
  }

  public get (id: string): Promise <Address> {
    return this.getAddressDescriptor(id)
      .then((address: Address) => this.getAddressFormatFromAddressDTO(address))
      .then(([addressDTO, addressFormatDTO]: [any, any]) => {
        return Address.build(addressDTO, addressFormatDTO)
      })
  }

  public getCached (id: string): Promise <Address> {
    return this.addressService.get([id], useCacheParams)
      .then((address: Address) => this.getAddressFormatFromAddressDTO(address))
      .then(([addressDTO, addressFormatDTO]: any): Address => Address.build(addressDTO, addressFormatDTO))
  }

  public getAddressFields (formatId: string, fieldId: string, params: {[index: string]: string} = {}, localeCode: string = ''): Promise <any> {
    const headers = {}
    if (localeCode) {
      headers[ACCEPT_LANG_HEADER] = generateLocaleData(localeCode).localeHeader
    }

    // if we populate locale code we should not cache result
    return this.addressFieldsService.get(
      [formatId, fieldId],
      localeCode ? params : Object.assign({}, useCacheParams, params),
      false,
      headers
    )
  }

  public getAddressFormat (id: string): Promise <any> {
    return this.addressFormatService.get([id], useCacheParams)
  }

  public getAddressFormats (): Promise <AddressFormat[]> {
    return this.addressFormats ?
      Promise.resolve(this.addressFormats.map(AddressFormat.build)) :
      this.addressFormatsService.get([], useCacheParams)
        .then((response: any[]) => {
          this.addressFormats = response.map(AddressFormat.build)
          return this.addressFormats
        })
  }

  public createAddress (addressDescriptor: Address): Promise <any> {
    return this.addressService.create(addressDescriptor)
  }

  public updateAddress (addressId: string, addressDescriptor: Address): Promise <any> {
    return this.addressService.update(addressDescriptor, [addressId])
  }

  public getConsumerAddresses (consumerId: string): Promise <Address[]> {
    return this.consumerAddressesService.get([consumerId])
      .then((addresses: Address[]) => this.getGivenAddressesWithAddressFormats(addresses))
  }

  public getGivenAddressesWithAddressFormats (addresses: any[]): Promise <Address[]> {
    return this.getAddressFormats()
      .then((addressFormats: AddressFormat[]) => {
        return addresses.map((addressDTO: any) => {
          const matchedAddressFormatDTO: AddressFormat = addressFormats.find((addressFormatDTO: AddressFormat) => {
            return addressDTO.addressFormatId === addressFormatDTO.id
          })
          return Address.build(addressDTO, matchedAddressFormatDTO || {})
        })
      })
  }

  public buildAddressGivenAddressDescriptor (addressDTO: any = {}): Promise <Address> {
    return this.getAddressFormats()
      .then((addressFormats: AddressFormat[]) => {
        const matchedAddressFormatDTO = addressFormats.find((addressFormatDTO: AddressFormat) => {
          return addressDTO.addressFormatId === addressFormatDTO.id
        })
        return Address.build(addressDTO, matchedAddressFormatDTO || {})
      })
  }

  public getConsumerAddress (consumerId: string, addressId: string): Promise <Address> {
    return this.getConsumerAddresses(consumerId)
      .then((addresses: Address[]) => addresses.find((address: Address) => address.id === addressId))
  }

  public createConsumerAddress (consumerId: string, addressDescriptor: Address): Promise <any> {
    return this.consumerAddressService.create(addressDescriptor, [consumerId])
  }

  public updateConsumerAddress (consumerId: string, addressId: string, addressDescriptor: Address): Promise <any> {
    return this.consumerAddressService.update(addressDescriptor, [consumerId, addressId])
  }

  public deleteConsumerAddress (consumerId: string, addressId: string): Promise <void> {
    return this.consumerAddressService.delete([consumerId, addressId])
  }

}

const addressCollectionFactory = new AddressCollectionFactory()
export default addressCollectionFactory
