import superagent from 'superagent'
import superagentCache from 'superagent-cache'
import get from 'lodash-es/get'
import envConfig from './envConfig'
import PubSub from './PubSub'
import localeHelper from './localeHelper'
import {getItemFromLocalStorage} from 'common/utils/storageUtils'
import {uuid4} from '@sentry/utils'

superagentCache(superagent) // turns on caching
const agentPubSub = new PubSub()

const DEFAULT_REQUEST_TIMEOUT_MS = 5000
const requestTimeoutPeriodMS = get(envConfig, 'requestTimeoutPeriodMS', DEFAULT_REQUEST_TIMEOUT_MS)

const HTTP_NOT_AUTHORIZED_STATUS_CODE = 401
const HTTP_NOT_FOUND_STATUS_CODE = 404
const UNAUTHED_REQUEST_EVENT = 'unauthed-request'
export const AUTH_HEADER = 'X-Authorization'
const DEVICE_ID_HEADER = 'X-Device-ID'
export const ACCEPT_LANG_HEADER = 'Accept-Language'
export const AUTH_STORAGE_KEY = 'draewilAuth'
const baseUrl = window.location.origin + envConfig.api.prefix

let authToken = getItemFromLocalStorage(AUTH_STORAGE_KEY, null)
let deviceId = getItemFromLocalStorage(DEVICE_ID_HEADER)
if (!deviceId) {
  deviceId = uuid4()
  localStorage.setItem(DEVICE_ID_HEADER, deviceId)
}

function removeAuthCookie () {
  document.cookie = `${AUTH_HEADER}=; Max-Age=-99999999;`
}

function getRequestFactory (method) {
  return function (path, body = null, queryParams = {}, optionalHeaders = {}, optionalFiles = {}, noCache = false) {
    if (method === 'get' && body) {
      throw new Error('Agent GET request can not have a body.')
    }
    /**
     * As superagent serialization doesn't seem to work properly with superagentPromise or superagentCache we have to serialize the body first by ourselves.
     * This is needed in case if object contains toJSON() method
     */
    let requestBody = body
    if (requestBody && typeof requestBody['toJSON'] === 'function') {
      const parsedBody = JSON.stringify(requestBody)
      requestBody = JSON.parse(parsedBody)
    }

    let requestPromise = null
    if (Object.keys(optionalFiles).length) {
      requestPromise = superagent[method](baseUrl + path)
        .field(requestBody)
        .field(queryParams)

      Object.keys(optionalFiles).forEach(fileKey => requestPromise.attach(fileKey, optionalFiles[fileKey]))
    } else {
      // NB. If a get requested with a body, superagent uses it as query params. Guard above.
      requestPromise = superagent[method](baseUrl + path, requestBody).query(queryParams)
    }

    return requestPromise
      .serialize(request => window.angular.toJson(request)) // to prevent sending $$hashKey and such keys, which being added by angular
      .forceUpdate(noCache)
      .preventDuplicateCalls(!noCache)
      .expiration(envConfig.cacheExpirationSeconds)
      .set(AUTH_HEADER, authToken)
      .set(ACCEPT_LANG_HEADER, localeHelper.getLocaleHeader())
      .set(DEVICE_ID_HEADER, deviceId)
      .set(optionalHeaders)
      .withCredentials() // Required for sending validation token cookie on CORS requests
      .timeout(requestTimeoutPeriodMS)
      .then(response => {
        if (response.body && response.headers['content-range']) {
          response.body.contentLength = response.headers['content-range']
        }
        return response.body
      })
      .catch(error => {
        conditionallyHandleErrorsByType(error)
        const errorDescriptor = error && error.response && error.response.body || {} // Use body if possible, else fall back
        errorDescriptor.status = error.status
        if (errorDescriptor.status !== HTTP_NOT_FOUND_STATUS_CODE) { // we still need to track down 404 errors in case of lost endpoints
          errorDescriptor.isBackendError = true // sentry will not trigger error report if this flag is true
        }
        return Promise.reject(errorDescriptor)
      })
  }
}

function setAuthToken (token) {
  authToken = token
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(AUTH_STORAGE_KEY, token)
  }
}

function clearAuthToken () {
  authToken = null
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_STORAGE_KEY)
  }

  removeAuthCookie()
}

function conditionallyHandleErrorsByType (error) {
  if (error && error.status === HTTP_NOT_AUTHORIZED_STATUS_CODE) {
    clearAuthToken()
    agentPubSub.publish(UNAUTHED_REQUEST_EVENT)
  }
}

function onUnauthedRequest (callback) {
  agentPubSub.subscribe(UNAUTHED_REQUEST_EVENT, callback)
}

export default {
  get: getRequestFactory('get'),
  put: getRequestFactory('put'),
  post: getRequestFactory('post'),
  'delete': getRequestFactory('del'), // Superagent exposes this as 'del'
  onUnauthedRequest,
  setAuthToken,
  clearAuthToken
}
