import { ShippingProviders } from 'common/constants/ShippingProviders'
import Cost from '../Cost'

const DEFAULT_DELIVERY_PROVIDER = ShippingProviders.other

export default class StorefrontAreaSettings {
  constructor (
    public draewilDeliveryCost: Cost,
    public otherDeliveryCost: Cost,
    public minimumCart: Cost,
    public deliveryProvider: ShippingProviders
  ) {}

  public static build (dto: any): StorefrontAreaSettings {
    return new StorefrontAreaSettings(
      Cost.build(dto.draewilDeliveryCost || {}),
      Cost.build(dto.otherDeliveryCost || {}),
      Cost.build(dto.minimumCart || {}),
      dto.deliveryProvider || DEFAULT_DELIVERY_PROVIDER
    )
  }
}
