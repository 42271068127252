import Cost from './Cost'
import PaginationParameters from './PaginationParameters'

export class ConsumerHistoryBalancePrecharge {
  constructor(
    public authorized: Cost,
    public captured: Cost,
    public voided: Cost
  ) {}

  static build(DTO: any): ConsumerHistoryBalancePrecharge {
    return new ConsumerHistoryBalancePrecharge(
      Cost.build(DTO.authorized),
      Cost.build(DTO.captured),
      Cost.build(DTO.voided)
    )
  }
}

export class ConsumerHistoryBalanceSubline {
  constructor(
    public amount: Cost,
    public balanceAfter: Cost,
    public method: string,
    public success: boolean,
    public card?: {
      brand: string,
      last4: string
    },
    public precharge?: ConsumerHistoryBalancePrecharge,
    public error?: {
      code: string,
      message: string
    }
  ) {}

  static build(DTO: any): ConsumerHistoryBalanceSubline {
    return new ConsumerHistoryBalanceSubline(
      Cost.build(DTO.amount),
      Cost.build(DTO.balanceAfter),
      DTO.method,
      DTO.success,
      DTO.card,
      DTO.precharge && ConsumerHistoryBalancePrecharge.build(DTO.precharge),
      DTO.error
    )
  }
}

export const enum ConsumerHistoryBalanceSublineMethods {
  card = 'card',
  cash = 'cash',
  balance = 'balance',
  voucher = 'voucher',
  'KNET direct' = 'KNET direct',
  KNET = 'KNET',
  'cybersource-sa-wm' = 'cybersource-sa-wm'
}

export const enum ConsumerBalanceOperations {
  adjustment = 'adjustment',
  topup = 'topup',
  autotopup = 'autotopup',
  voucher = 'voucher',
  quote = 'quote',
  cancellation = 'cancellation',
  tip = 'tip'
}

export class ConsumerHistoryBalanceItem {
  constructor(
    public id: string,
    public date: Date,
    public operation: ConsumerBalanceOperations,
    public balanceAfter: Cost,
    public deliveryId?: string,
    public deliveryPaymentMethods?: string[],
    public deliveryRef?: string,
    public deliveryType?: string,
    public sublines?: ConsumerHistoryBalanceSubline[]
  ) {}

  static build(DTO: any): ConsumerHistoryBalanceItem {
    return new ConsumerHistoryBalanceItem(
      DTO.id,
      new Date(DTO.date),
      DTO.operation,
      Cost.build(DTO.balanceAfter),
      DTO.deliveryId,
      DTO.deliveryPaymentMethods,
      DTO.deliveryRef,
      DTO.deliveryType,
      DTO.sublines.map(ConsumerHistoryBalanceSubline.build)
    )
  }
}

export class ConsumerHistoryBalance implements IRecordsAndMetadata<ConsumerHistoryBalanceItem> {
  constructor (
    public records: ConsumerHistoryBalanceItem[],
    public metadata: PaginationParameters,
    public currentBalance: Cost
  ) {}

  static build(DTO: any): ConsumerHistoryBalance {
    return new ConsumerHistoryBalance(
      DTO.records.map(ConsumerHistoryBalanceItem.build),
      PaginationParameters.build(DTO.metadata),
      Cost.build(DTO.currentBalance)
    )
  }
}
