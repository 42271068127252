import AddressFieldDefinition from './AddressFieldDefinition'

export default class AddressFormat {
  constructor (
    public readonly id: string = '',
    public readonly countryCode: string = '',
    public readonly type: string = '',
    public readonly presentation: {mapOnTop: boolean} = {mapOnTop: false},
    public readonly fields: AddressFieldDefinition[] = []
  ) {}

  public getFieldDefinitionReferrence(fieldName: string): AddressFieldDefinition | undefined {
    return this.fields.find((field: AddressFieldDefinition): boolean => field.name === fieldName)
  }

  public static build (dto: any = {}): AddressFormat {
    const presentation = {
      mapOnTop: false
    }
    if (dto.presentation && Array.isArray(dto.presentation) && dto.presentation.includes('mapOnTop')) {
      presentation.mapOnTop = true
    }

    return new AddressFormat(
      dto.id || '',
      dto.countryCode || '',
      dto.type || '',
      presentation,
      Array.isArray(dto.fields) ? dto.fields.map(AddressFieldDefinition.build) : []
    )
  }
}
