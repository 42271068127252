import get from 'lodash-es/get'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import businessesCollection from 'data/collections/businessesCollection'
import BranchEntity from 'data/domain-objects/BranchEntity'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import * as PaymentMethodsOptions from 'common/constants/PaymentMethodsSettings'
import StorefrontBranchAreasConfiguration from 'data/domain-objects/storefront/StorefrontBranchAreasConfiguration'

const businessBranchService = new CrudService(endpoints.BUSINESS_BRANCH)
const branchService = new CrudService(endpoints.BRANCH)
const branchAllLanguagesService = new CrudService(endpoints.BRANCH_ALL_LANGUAGES)
const branchSettingsService = new CrudService(endpoints.BUSINESS_BRANCH_SETTINGS)
const branchBalanceHistoryService = new CrudService(endpoints.BRANCH_BALANCE_HISTORY)
const branchBalancesService = new CrudService(endpoints.BUSINESS_BRANCH_BALANCES)
const branchStorefrontSettingsAllLanguagesService = new CrudService(endpoints.BUSINESS_BRANCH_STOREFRONT_SETTINGS_ALL_LANGUAGES)
const branchStorefrontSettingsService = new CrudService(endpoints.BUSINESS_BRANCH_STOREFRONT_SETTINGS)
const branchAreasStorefrontSettingsService = new CrudService(endpoints.BUSINESS_BRANCH_AREAS_STOREFRONT_SETTINGS)
const useCacheParams = {useCache: true}

export interface IBranchSettingsParams {
  merge: boolean
}

const getPaymentMethodsFromSettings = (settings: any, settingsKeys: any): any => {
  const availablePaymentMethods = []
  for (const paymentOption of PaymentMethodsOptions.ALL_PAYMENTS_OPTIONS) {
    const settingName = settingsKeys[paymentOption]
    const settingValue = get(settings, `${settingName}.value`, false)
    if (settingValue) {
      availablePaymentMethods.push(paymentOption)
    }
  }
  return availablePaymentMethods.slice()
}

const branchesCollection = {
  getWithAllLanguages: (branchId: string): Promise<any> => {
    return branchAllLanguagesService.get([branchId])
  },

  getCached: (branchId: string): Promise<any> => {
    return branchService.get([branchId], useCacheParams)
  },

  getAllWithoutAddresses: (): Promise<any> => {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then((businessesWithBranches: any[]): any[] => {
        let branchesList = []
        businessesWithBranches.forEach((business: any): any[] => branchesList = branchesList.concat(business.branches))
        return branchesList
      })
  },

  getBranchWithBalance: (branchId: string): Promise<any> => {
    return branchService.get([branchId], {populate: 'cashBalanceAccount'})
  },

  getBranchSettings: (businessId: string, branchId: string, params?: IBranchSettingsParams): Promise<any> => {
    return branchSettingsService.get([businessId, branchId], params)
  },

  updateBranchSettings: (businessId: string, branchId: string, settings: any): Promise<any> => {
    return branchSettingsService.update(settings, [businessId, branchId])
  },

  delete: (businessId: string, branchId: string): Promise<void> => {
    return branchService.delete([businessId, branchId])
  },

  create: (branch: any, businessId: string): Promise<BranchEntity> => {
    return businessBranchService.create(branch, [businessId])
      .then(BranchEntity.build)
  },

  update: (branchId: string, branch: any): Promise<BranchEntity> => {
    return branchService.update(branch, [branchId])
      .then(BranchEntity.build)
  },

  getHistory: (id: string, filters: any): Promise<any> => {
    return branchBalanceHistoryService.get([id], filters)
  },

  getBalances: (businessId: string, branchId: string): Promise<any> => {
    return branchBalancesService.get([businessId, branchId])
  },

  getPaymentMethods: (settings: any): any => {
    return getPaymentMethodsFromSettings(settings, PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_MAPPING)
  },

  getRequestPaymentMethods: (settings: any): any => {
    return getPaymentMethodsFromSettings(settings, PaymentMethodsOptions.REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING)
  },

  getStorefrontSettings: (businessId: string, branchId: string): Promise<any> => {
    return branchStorefrontSettingsAllLanguagesService.get([businessId, branchId])
  },

  updateStorefrontSettings: (settings: any, businessId: string, branchId: string): Promise<any> => {
    return branchStorefrontSettingsService.update(settings, [businessId, branchId])
  },

  getBranchNamesById: (): Promise<any> => {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then((response: any[]) => {
        return response.reduce((accum: object, business: any): object => {
          business.branches.forEach((branch: DBMappedNamedEntity): void => {
            accum[branch.id] = `${business.name} - ${branch.name}`
          })

          return accum
        }, {})
      })
  },

  getBranchAreasSettings: (businessId: string, branchId: string): Promise<StorefrontBranchAreasConfiguration[]> => {
    return branchAreasStorefrontSettingsService.get([businessId, branchId])
      .then((result: any[]): StorefrontBranchAreasConfiguration[] => result.map(StorefrontBranchAreasConfiguration.build))
  },

  updateBranchAreasSettings: (
    settings: StorefrontBranchAreasConfiguration[],
    businessId: string,
    branchId: string
  ): Promise<StorefrontBranchAreasConfiguration[]> => {
    return branchAreasStorefrontSettingsService.update(settings, [businessId, branchId])
      .then((result: any[]): StorefrontBranchAreasConfiguration[] => result.map(StorefrontBranchAreasConfiguration.build))
  }
}

export default branchesCollection
