import * as moment from 'moment'
import Cost from './Cost'
import { PROMOCODE_STATUSES } from 'common/constants/PromocodeStatuses'

class AvailableFleetCost {
  constructor (
    public value: number,
    public minCost: number = null,
    public maxCost: number = null,
    public currencyCode: string
  ) {}

  getFormattedCost (): string {
    if (this.minCost !== null && this.maxCost !== null) {
      return `
        ${this.currencyCode.toUpperCase()} ${this.minCost.toFixed(Cost.getDecimalPointsCountForCurrency(this.currencyCode))}
        -
        ${this.currencyCode.toUpperCase()} ${this.maxCost.toFixed(Cost.getDecimalPointsCountForCurrency(this.currencyCode))}
      `
    }

    return new Cost(this.value, this.currencyCode).getFormattedCost(false)
  }
}

export class AvailableFleet {
  public isPromoCodeApplied: boolean

  constructor (
    public id: string = '',
    public name: string = '',
    public description: string = '',
    public cost: AvailableFleetCost,
    public logoUrl: string = '',
    public ETA: string,
    public ETD: string,
    public promoCodeStatus: string
  ) {
    this.isPromoCodeApplied = (this.promoCodeStatus === PROMOCODE_STATUSES.APPLIED)
  }

  static build (dto: any = {}): AvailableFleet {

    const minCost = dto.minCost && parseFloat(dto.minCost.amount) || null
    const maxCost = dto.maxCost && parseFloat(dto.maxCost.amount) || null
    const cost = new AvailableFleetCost(parseFloat(dto.cost.amount), minCost, maxCost, dto.cost.currencyCode)

    return new AvailableFleet(
      dto.fleetId || '',
      dto.fleetName || '',
      dto.description || '',
      cost,
      dto.fleetLogoUrl || '',
      dto.fleetETA ? moment().add(dto.fleetETA, 'seconds').toISOString() : '',
      dto.fleetETD ? moment().add(dto.fleetETD, 'seconds').toISOString() : '',
      dto.promoCodeStatus || null
    )
  }
}

export default class Fleet {
  constructor (
    public id: string = '',
    public name: string = '',
    public description: string = '',
    public contactName: string = '',
    public contactEmail: string = '',
    public contactCountryDiallingCode: string,
    public contactPhoneNumber: string,
    public logoUrl: string = '',
    public areas: any[] = [],
    public services: any[] = [],
    public restrictedToBranches: any[] = [],
    public cost: Cost,
    public ETA: string = '',
    public ETD: string = '',
    public tariffCount: number
  ) {}

  static build (dto: any = {}): Fleet {
    return new Fleet(
      dto.id || dto.fleetId || '',
      dto.name || dto.fleetName || '',
      dto.description || dto.fleetDescription || '',
      dto.contactName || '',
      dto.contactEmail || '',
      dto.contactCountryDiallingCode || '',
      dto.contactPhoneNumber || '',
      dto.logoUrl || dto.fleetLogoUrl || '',
      dto.areas || [],
      dto.services || [],
      dto.restrictedToBranches || [],
      Cost.build(dto.cost),
      dto.ETA || moment().add(dto.fleetETA, 'seconds').toISOString() || '',
      dto.ETD || (dto.fleetETD ? moment().add(dto.fleetETD, 'seconds').toISOString() : ''),
      dto.tariffCount || null
    )
  }
}
