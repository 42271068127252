import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const useCacheParam = {useCache: true}
const deliveryTrackingSettingsService = new CrudService(endpoints.DELIVERY_TRACKING_SETTINGS)
const deliveryVerifyAddressService = new CrudService(endpoints.DELIVERY_TRACKING_VERIFY_ADDRESS)

function addressVerificationFactory () {
  function get (deliveryId) {
    return deliveryTrackingSettingsService.get([deliveryId], useCacheParam)
  }

  function saveLocale (deliveryId, locale) {
    return deliveryTrackingSettingsService.create({locale}, [deliveryId])
  }

  function verifyAddress (deliveryId, addressLocation) {
    return deliveryVerifyAddressService.update(addressLocation, [deliveryId])
  }

  return {
    get,
    saveLocale,
    verifyAddress
  }
}

export default addressVerificationFactory()
