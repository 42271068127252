export default class AddressFieldDefinition {
  public presentation: {displayInline: boolean}

  constructor(
    public id: string = '',
    public name: string = '',
    public dependsOn: string[] = [],
    public required: boolean = false,
    public type: string = '',
    public userFriendlyName: string = '',
  ) {
    this.presentation = {displayInline: true}
  }

  public static build(dto: any): AddressFieldDefinition {
    let dependsOn = []
    if (typeof dto.dependsOn === 'string') {
      dependsOn = dto.dependsOn.split(',')
    } else if (Array.isArray(dto.dependsOn)) {
      dependsOn = dto.dependsOn
    }

    return new AddressFieldDefinition(
      dto.id,
      dto.name,
      dependsOn,
      !!dto.required,
      dto.type,
      dto.userFriendlyName
    )
  }
}
