import Cost from './Cost'
import {PaymentMethod} from 'common/constants/PaymentMethods'

export default class PaymentData {
  constructor (
    public cost: Cost,
    public method: PaymentMethod,
    public posReference: string
  ) {}

  static build (dto: any = {}): PaymentData {
    return new PaymentData(
      Cost.build(dto.cost),
      dto.method || dto.paymentMethod,
      dto.posReference || dto.pos
    )
  }
}
