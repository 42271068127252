import localeHelper from 'common/localeHelper'
import envConfig from 'common/envConfig'

function getDirAttributeValue (languageCode) {
  const rightToLeftLanguageCodes = envConfig.rightToLeftLanguages
  const dirValue = (rightToLeftLanguageCodes.indexOf(languageCode) >= 0) ? 'rtl' : 'ltr'

  return dirValue
}

function setAttributes ($attrs, $scope) {
  $attrs.$set('lang', $scope.langValue)
  $attrs.$set('dir', $scope.dirValue)
}

export default function languageAttributes () {
  return {
    restrict: 'A',
    link: function ($scope, $element, $attrs) {
      $scope.langValue = localeHelper.getLanguage()
      $scope.dirValue = getDirAttributeValue($scope.langValue)

      $scope.$watch(
        () => {
          return localeHelper.getLanguage()
        },
        language => {
          $scope.langValue = language
          $scope.dirValue = getDirAttributeValue(language)

          setAttributes($attrs, $scope)
        }
      )

      setAttributes($attrs, $scope)
    }
  }
}
