export default class LocationPoint {
  constructor(
    public latitude: number,
    public longitude: number
  ) {}

  public static build(dto: any = {}): LocationPoint {
    const latitude: number = parseFloat(dto.latitude)
    const longitude: number = parseFloat(dto.longitude)

    return new LocationPoint(
      latitude,
      longitude
    )
  }
}
