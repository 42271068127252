import Logger from 'common/Logger'
import agent from 'common/agent'
import cloneDeep from 'lodash-es/cloneDeep'
const cache = {}

class CrudService {
  constructor (endpointDescriptor) {
    if (!endpointDescriptor || !endpointDescriptor.entityName || !endpointDescriptor.endpointPath) {
      throw new Error('Crud Service must be instantiated with an entity descriptor with an entity name and endpoint path')
    }

    this.entityName = endpointDescriptor.entityName
    this.endpointPath = endpointDescriptor.endpointPath
    this.endpointHeaders = endpointDescriptor.endpointHeaders
    this.logger = new Logger(`Crud Service for ${this.entityName}`)
  }

  create (entity, ids = [], params = {}, files = {}) {
    const headers = (this.endpointHeaders && this.endpointHeaders.post) || {}
    this.logger.info(`Creating ${this.entityName} for entity given ids and params`, entity, ids, params)
    return safePromiseValue(() => getPathGivenTemplateAndIds(this.endpointPath, ids))
      .then(endpointPath => agent.post(endpointPath, entity, params, headers, files))
  }

  getCacheKey (ids, params = '') {
    return getPathGivenTemplateAndIds(this.endpointPath, ids) + JSON.stringify(params)
  }

  setCachedValue (ids, params, value) {
    const key = this.getCacheKey(ids, params)
    cache[key] = value
  }

  getCachedValue (ids, params) {
    const key = this.getCacheKey(ids, params)
    return (cache.hasOwnProperty(key)) ? cloneDeep(cache[key]) : null
  }

  get (ids = [], params = {}, noCache = false, dynamicHeaders = {}) {
    let headers = (this.endpointHeaders && this.endpointHeaders.get) || {}
    headers = Object.assign(headers, dynamicHeaders)
    this.logger.info(`Getting ${this.entityName} given ids and params`, ids, params)

    const cachedValue = this.getCachedValue(ids, params)

    if (params.useCache && cachedValue !== null) {
      return Promise.resolve(cachedValue)
    }

    const serverSentParams = Object.assign({}, params)
    delete serverSentParams.useCache

    const promisedResult = safePromiseValue(() => getPathGivenTemplateAndIds(this.endpointPath, ids))
      .then(endpointPath => agent.get(endpointPath, null, serverSentParams, headers, {}, noCache))

    if (params.useCache) {
      promisedResult.then(result => {
        this.setCachedValue(ids, params, result)
        return result
      })
    }

    return promisedResult
  }

  update (entity, ids = [], params = {}) {
    const headers = (this.endpointHeaders && this.endpointHeaders.put) || {}
    this.logger.info(`Updating ${this.entityName} given entity, ids and params`, entity, ids, params)
    return safePromiseValue(() => getPathGivenTemplateAndIds(this.endpointPath, ids))
      .then(endpointPath => agent.put(endpointPath, entity, params, headers))
  }

  'delete' (ids = [], params = {}) {
    const headers = (this.endpointHeaders && this.endpointHeaders.delete) || {}
    this.logger.info(`Deleting ${this.entityName} given ids and params`, ids, params)
    return safePromiseValue(() => getPathGivenTemplateAndIds(this.endpointPath, ids))
      .then(endpointPath => agent.delete(endpointPath, null, params, headers))
  }
}

function getPathGivenTemplateAndIds (rawPathTemplate, ids) {
  const CURLY_BRACED_TERM = /{([^}]+)}/
  const INNER_CURLY_BRACED_TERMS = /{([^}]+)}(?!$)/g
  const TRAILING_CURLY_BRACED_TERM = /{([^}]+)}$/
  const TRAILING_SLASH = /\/$/ // Strip any trailing slash before templating
  const pathTemplate = rawPathTemplate.replace(TRAILING_SLASH, '')

  const idCount = ids.length
  const innerTermMatches = pathTemplate.match(INNER_CURLY_BRACED_TERMS)
  const innerTermCount = innerTermMatches ? innerTermMatches.length : 0
  const hasTrailingTerm = !!pathTemplate.match(TRAILING_CURLY_BRACED_TERM)
  const trailingTermCount = hasTrailingTerm ? 1 : 0

  if (idCount === (innerTermCount + trailingTermCount)) {
    return substituteValuesIntoTemplate(pathTemplate, CURLY_BRACED_TERM, ids)
  } else if (idCount === innerTermCount && hasTrailingTerm) {
    return substituteValuesIntoTemplate(pathTemplate, CURLY_BRACED_TERM, ids.concat([''])) // Provide empty trailing term
  } else {
    throw new Error('Mismatch between number of ids passed for path and number of terms in path')
  }
}

function safePromiseValue (callback) {
  try {
    return Promise.resolve(callback())
  } catch (error) {
    return Promise.reject(error)
  }
}

function substituteValuesIntoTemplate (template, regex, values) {
  return values.reduce((workingTemplate, value) => {
    const valueString = value ? value + '' : ''
    return workingTemplate.replace(regex, valueString)
  }, template)
}

export {
  getPathGivenTemplateAndIds
}

export default CrudService
