import MultiLangString from './MultiLangString'
import { AccountTypes } from 'common/constants/AccountTypes'
import User from './User'
import { BusinessDocument } from './BusinessDocument'

export class BusinessRegistrationData {
  constructor(
    public id: string = '',
    public bankId: string = '',
    public name: MultiLangString = new MultiLangString(),
    public type: AccountTypes = AccountTypes.BUSINESS,
    public iban: string = '',

    public website: string = '',

    public contacts: User[] = [],

    public authorizedSignature: BusinessDocument = new BusinessDocument(),
    public civilId: BusinessDocument = new BusinessDocument(),
    public license: BusinessDocument = new BusinessDocument()
  ) {}

  public static build(dto: any = {}): BusinessRegistrationData {
    return new BusinessRegistrationData(
      dto.id || '',
      dto.bankId || '',
      MultiLangString.build(dto.name),
      dto.type,
      dto.iban,

      dto.website,

      (Array.isArray(dto.contacts) ? dto.contacts : []).map(User.build),

      BusinessDocument.build(dto.authorizedSignature),
      BusinessDocument.build(dto.civilId),
      BusinessDocument.build(dto.license)
    )
  }
}
