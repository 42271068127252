export enum PaymentMethod {
  cash = 'cash',
  card = 'card',
  knet = 'knet',
  balance = 'balance',
  cashOffSystem = 'cashOffSystem',
  paidOffSystem = 'paidOffSystem',
  cashOnDelivery = 'cashOnDelivery'
}

export const CASH = PaymentMethod.cash
export const CARD = PaymentMethod.card
export const KNET = PaymentMethod.knet
export const BALANCE = PaymentMethod.balance
export const CASH_OFF_SYSTEM = PaymentMethod.cashOffSystem
export const PAID_OFF_SYSTEM = PaymentMethod.paidOffSystem
export const CASH_ON_DELIVERY = PaymentMethod.cashOnDelivery
