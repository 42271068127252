import envConfig from 'common/envConfig'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import {AvailableFleet} from 'data/domain-objects/Fleet'
import Fleet from 'data/domain-objects/Fleet'

const fleetsService = new CrudService(endpoints.FLEETS)
const fleetService = new CrudService(endpoints.FLEET)
const fleetAllLanguagesService = new CrudService(endpoints.FLEET_ALL_LANGUAGES)
const fleetGlobalService = new CrudService(endpoints.FLEET_GLOBAL)
const availableFleetsForDelivery = new CrudService(endpoints.DELIVERY_AVAILABLE_FLEETS)
const availableFleetsForNextDayDelivery = new CrudService(endpoints.DELIVERY_NEXT_DAY_AVAILABLE_FLEETS)
const fleetHistoryService = new CrudService(endpoints.FLEET_TRANSACTIONS_HISTORY)
const fleetSettingsService = new CrudService(endpoints.FLEET_SETTINGS)
const fleetDefaultSettingsService = new CrudService(endpoints.TENANT_SETTINGS)
const availableRideFleets = new CrudService(endpoints.RIDE_AVAILABLE_FLEETS)

const useCacheParam = {useCache: true}

export function fleetsCollectionFactory () {
  const get = function (fleetId) {
    return fleetService.get([fleetId])
  }

  const getWithAllLanguages = function (fleetId) {
    return fleetAllLanguagesService.get([fleetId])
  }

  const getCached = function (fleetId) {
    return fleetService.get([fleetId], useCacheParam)
  }

  const getWithBalanceAccount = function (fleetId) {
    return fleetService.get([fleetId], {populate: 'balanceAccount'})
  }

  const getAll = function () {
    return fleetsService.get([])
      .then(fleets => fleets.map(fleet => Fleet.build(fleet)))
  }

  const getAllCached = function () {
    return fleetsService.get([], useCacheParam)
      .then(fleets => fleets.map(fleet => Fleet.build(fleet)))
  }

  const updateGlobal = function (fleet, global) { // Update global status and re-fetch fleet with latest status
    return fleetGlobalService.update({global}, [fleet.id]).then(() => getWithAllLanguages([fleet.id]))
  }

  const getAvailableFleets = function (params, isNextDay) {
    const requestPromise = (isNextDay)
      ? availableFleetsForNextDayDelivery.create(params)
      : availableFleetsForDelivery.create(params)
    return requestPromise.then(fleets => fleets.map(fleetDTO => Fleet.build(fleetDTO)))
  }

  const deleteFleet = function (fleetId) {
    return fleetService.delete([fleetId])
  }

  const getHistory = function (fleetId, filters) {
    return fleetHistoryService.get([fleetId], filters)
  }

  const getSettings = function (fleetId) {
    return fleetId ? fleetSettingsService.get([fleetId]) : fleetDefaultSettingsService.get([envConfig.tenantDefaultId])
  }

  const setSettings = function (settingsModel, fleetId) {
    return fleetSettingsService.update(settingsModel, [fleetId])
  }

  const getSettingsCached = function (fleetId) {
    return fleetSettingsService.get([fleetId], useCacheParam)
  }

  const update = function (fleetModel, fleetId) {
    return fleetService.update(fleetModel, [fleetId])
  }

  const create = function (fleetModel) {
    return fleetService.create(fleetModel)
  }

  const getAvailableRideFleets = params => {
    return availableRideFleets.create(params).then(fleets => fleets.map(AvailableFleet.build))
  }

  return {
    get,
    getWithAllLanguages,
    getCached,
    getWithBalanceAccount,
    getAll,
    getAllCached,
    getAvailableFleets,
    'delete': deleteFleet,
    getHistory,
    getSettings,
    setSettings,
    getSettingsCached,
    updateGlobal,
    update,
    create,
    getAvailableRideFleets
  }
}

export default fleetsCollectionFactory()
