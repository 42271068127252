import angular from 'angular'

const modulesCache = {}
export default function declareAppModule (moduleName, callback, dependencies = []) {
  if (!modulesCache.hasOwnProperty(moduleName)) {
    const appModuleName = 'app.' + moduleName
    const module = angular.module(appModuleName, dependencies) // Note that this is stateful
    callback(module)
    modulesCache[moduleName] = true
    return appModuleName
  } else {
    throw new Error(`declareAppModule for module ${moduleName} called twice. Copy/paste detected`)
  }
}
