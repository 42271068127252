import AddressField from 'data/domain-objects/AddressField'
import LocationPoint from './LocationPoint'
import BaseAddressTemplate from './BaseAddressTemplate'

export default class Address extends BaseAddressTemplate {
  constructor (
    public id: string = '',
    public area: string = '',
    location: LocationPoint,
    public type: string,
    fields: AddressField[] = [],
    public name: string = '',
    public verified: boolean = false,
    public createdAt: string = '',
    public timezoneId: string = '',
    public lastDeliveryCreatedAt: string = '',
    addressFormatId: string = '',
    public consumerFirstName: string = '',
    public consumerLastName: string = '',
    public detached: boolean = false
  ) {
    super(addressFormatId, fields, location)
  }

  static build (
    addressDTO: any = {},
    addressFormatDTO: any = {},
    consumerFirstName: string = '',
    consumerLastName: string = ''
  ): Address {
    return new Address(
      addressDTO.id,
      addressDTO.area,
      LocationPoint.build(addressDTO.location),
      addressFormatDTO.type || addressDTO.type,
      Array.isArray(addressDTO.fields) ? addressDTO.fields.map(AddressField.build) : [],
      addressDTO.name,
      !!addressDTO.verified,
      addressDTO.createdAt,
      addressDTO.timezoneId,
      addressDTO.lastDeliveryCreatedAt,
      addressDTO.addressFormatId,
      addressDTO.consumerFirstName || consumerFirstName || '',
      addressDTO.consumerLastName || consumerLastName || '',
      addressDTO.detached || false
    )
  }

  getConsumerFullName (): string {
    return `${this.consumerFirstName} ${this.consumerLastName}`
  }
}
