import localeHelper from 'common/localeHelper'

const TEMPLATE_PREFIX = 'COMPONENTS.SPECIFIC_TIME_TO.TEMPLATE.'
const HUMANISED_PAST_TEMPLATE_PREFIX = 'COMPONENTS.SPECIFIC_TIME_TO.PAST_HUMANISED_TEMPLATE.'
const HUMANISED_FUTURE_TEMPLATE_PREFIX = 'COMPONENTS.SPECIFIC_TIME_TO.FUTURE_HUMANISED_TEMPLATE.'
const MINIMUM_DURATION_MS = 60000
const MINUTES_THRESHOLD = 60
const MS_PER_MIN = 60000
const MS_PER_HOUR = 3600000
const MS_PER_DAY = 86400000

export default function specificTimeToFilter () {
  function specificTimeTo (givenIsoTime, formatForPast, humanised, wrongFormat = '-') {
    const givenTimestamp = Date.parse(givenIsoTime)
    if (typeof givenIsoTime !== 'string' || isNaN(givenTimestamp)) {
      return wrongFormat
    }

    const rawDurationMS = givenTimestamp - Date.now()
    const durationToGivenTimeMs = getClampedDuration(rawDurationMS, formatForPast)
    return getFormattedShortDuration(durationToGivenTimeMs, humanised, formatForPast)
  }

  function getClampedDuration (durationMS, formatForPast) {
    // If formatting for past, it has to occur at least one minimum duration in the past. And vice versa for the future.
    const minDurationMS = formatForPast ? Math.min(durationMS, -1 * MINIMUM_DURATION_MS) : Math.max(durationMS, MINIMUM_DURATION_MS)
    return Math.abs(minDurationMS)
  }

  function getFormattedShortDuration (durationToGivenTimeMs, humanised, formatForPast) {
    const durationMins = getUnitCountForDuration(durationToGivenTimeMs, MS_PER_MIN)
    const durationHours = getUnitCountForDuration(durationToGivenTimeMs, MS_PER_HOUR)
    const durationDays = getUnitCountForDuration(durationToGivenTimeMs, MS_PER_DAY)
    let result = ''
    if (durationDays > 0) {
      result = getUnitTemplate(durationDays, false, false, humanised, formatForPast).replace('{1}', durationDays)
    } else if (durationMins > MINUTES_THRESHOLD) {
      result = getUnitTemplate(durationHours, false, true, humanised, formatForPast).replace('{1}', durationHours)
    } else {
      result = getUnitTemplate(durationMins, true, false, humanised, formatForPast).replace('{1}', durationMins)
    }
    return result
  }

  function getUnitCountForDuration (durationMs, unitPeriodMs) {
    return Math.floor(durationMs / unitPeriodMs)
  }

  function getUnitTemplate (count, isMinutes, isHours, humanise, formatForPast) {
    const unitKey = getUnitKey(count, isMinutes, isHours)
    const humanisedKey = formatForPast ? HUMANISED_PAST_TEMPLATE_PREFIX : HUMANISED_FUTURE_TEMPLATE_PREFIX
    const keyPrefix = humanise ? humanisedKey : TEMPLATE_PREFIX
    const key = keyPrefix + unitKey
    return localeHelper.lookupTranslationKey(key)
  }

  function getUnitKey (count, isMinutes, isHours) {
    let countPrefix
    if (count === 1) { // This was shorter than a switch
      countPrefix = 'ONE_'
    } else if (count === 2) {
      countPrefix = 'TWO_'
    } else {
      countPrefix = 'MANY_'
    }

    if (isMinutes) {
      return countPrefix + (count === 1 ? 'MINUTE' : 'MINUTES')
    } else if (isHours) {
      return countPrefix + (count === 1 ? 'HOUR' : 'HOURS')
    } else {
      return countPrefix + (count === 1 ? 'DAY' : 'DAYS')
    }
  }

  return specificTimeTo
}
