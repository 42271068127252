export default class PhoneNumber {
  constructor (
    public countryDiallingCode: string = '',
    public phoneNumber: string = ''
  ) {}

  static build (phoneNumberDTO: any = {}): PhoneNumber {
    return new PhoneNumber(
      phoneNumberDTO.countryDiallingCode || phoneNumberDTO.contactCountryDiallingCode || '',
      phoneNumberDTO.phoneNumber || phoneNumberDTO.contactPhoneNumber || ''
    )
  }

  getFormattedPhone (): string {
    return (this.countryDiallingCode && this.phoneNumber) ? `+${this.countryDiallingCode} ${this.phoneNumber}` : '-'
  }
}
