import get from 'lodash-es/get'
import toUpperSnakeCase from 'common/toUpperSnakeCase'
import envConfig from './envConfig'
import {getItemFromLocalStorage} from 'common/utils/storageUtils'
import * as moment from 'moment'
import superagent from 'superagent'
import PubSub from 'common/PubSub'

const LOCALE_STORAGE_KEY = 'draewilLocale'
const LOCALE_STORAGE_CACHE_KEY = 'draewilLocaleCache'

export const generateLocaleData = (locale: string): ILocaleData => {
  const localeParts = locale.split('_')
  const language = localeParts[0]

  return {
    code: locale,
    languageTranslationKey: 'COMPONENTS.LOCALE_PICKER.' + language.toUpperCase(),
    language,
    country: localeParts[1],
    localeHeader: `${localeParts[0]}-${localeParts[1].toUpperCase()},${localeParts[0]};q=0.9,en`
  }
}

export class LocaleHelper {

  private i18nCache: any = {}
  private locale: string = ''
  private localeHeader: string = ''
  private localeData: ILocaleData
  private pubSub: PubSub = new PubSub()
  private EVENT_CHANGE_LOCALE: string = 'change-locale-event'

  static getCacheKey(lang: string): string {
    return `${LOCALE_STORAGE_CACHE_KEY}_${lang}`
  }

  static sanitiseKeyString (str: string = ''): string {
    return str.trim().split('.').map(toUpperSnakeCase).join('.')
  }

  init(): Promise<void> {
    this.locale = getItemFromLocalStorage(LOCALE_STORAGE_KEY, envConfig.defaultLocaleCode || 'en_GB')
    this.localeData = generateLocaleData(this.locale)
    this.generateLocaleHeader()
    return this.loadI18NResources(this.localeData.language)
  }

  publicLocaleChangeEvent (): void {
    this.pubSub.publish(this.EVENT_CHANGE_LOCALE)
  }

  subscribeOnLocaleChange (callback: any): any {
    return this.pubSub.subscribe(this.EVENT_CHANGE_LOCALE, callback)
  }

  loadI18NResources(lang: string): Promise<void> {
    const i18nCacheKey = LocaleHelper.getCacheKey(lang)
    const savedI18N = getItemFromLocalStorage(i18nCacheKey)
    const version = envConfig.version || Date.now() // no cache in development

    if (savedI18N !== null && savedI18N.hasOwnProperty(version)) {
      this.i18nCache[ lang ] = savedI18N[version]
      return Promise.resolve()
    } else {
      if (this.i18nCache.hasOwnProperty(lang)) {
        return Promise.resolve()
      }

      return superagent.get(envConfig.i18nResources.replace('{lang}', lang)).query({ts: Date.now()})
        .then((res: any) => {
          window.localStorage.removeItem(i18nCacheKey)
          const storageValue = {}
          storageValue[ version ] = res.body

          window.localStorage.setItem(i18nCacheKey, JSON.stringify(storageValue))
          this.i18nCache[ lang ] = res.body
          return Promise.resolve()
        },
        (err: Error) => {
          return Promise.reject(err)
        })
    }
  }

  lookupTranslationKey (keyString: string, language: string = this.localeData.language): string {
    const translations = this.i18nCache[language] || {}
    const sanitisedKeyString = LocaleHelper.sanitiseKeyString(keyString)
    return get(translations, sanitisedKeyString, keyString)
  }

  getLocale (): string {
    return this.locale
  }

  getLocaleData (): ILocaleData {
    return this.localeData
  }

  getLanguage (): string {
    return this.localeData.language
  }

  private fixBrokenLocales (newLocale: string): string {
    return (envConfig.supportedLocales.indexOf(newLocale) > -1) ? newLocale : envConfig.defaultLocaleCode
  }

  onLocaleChange (newLocale: string): Promise<void> {
    this.locale = this.fixBrokenLocales(newLocale)
    this.generateLocaleHeader()
    localStorage.setItem(LOCALE_STORAGE_KEY, this.locale)
    this.localeData = generateLocaleData(this.locale)
    moment.locale(this.getLanguage())
    return this.loadI18NResources(this.localeData.language)
      .then(() => {
        this.publicLocaleChangeEvent()
        return Promise.resolve()
      })
  }

  private generateLocaleHeader (): void {
    const localeParts = this.locale.split('_')
    this.localeHeader = `${localeParts[0]}-${localeParts[1].toUpperCase()},${localeParts[0]};q=0.9,en`
  }

  getLocaleHeader (): string {
    return this.localeHeader
  }
}

const localeHelper = new LocaleHelper()
export default localeHelper
