import AddressField from './AddressField'
import LocationPoint from './LocationPoint'

const LATLONG_FORMAT_DPS = 3
const ADDITIONAL_DIRECTIONS_FIELD_NAME = 'additionalDirections'

export default class BaseAddressTemplate {
  public type: string
  constructor (
    public addressFormatId: string = '',
    public fields: AddressField[] = [],
    public location: LocationPoint = LocationPoint.build()
  ) {}

  getFormattedAddress (): string {
    const formattedFieldsString = this.fields
      .filter((field: AddressField) => (!!field.value && field.name !== ADDITIONAL_DIRECTIONS_FIELD_NAME))
      .map((field: AddressField): string => field.value)
      .join(', ')
    const formattedLocationString = this.getFormattedLocation()
    return formattedFieldsString || formattedLocationString
  }

  getAdditionalDirections (): string {
    return this.fields.find((field: AddressField): boolean => field.name === ADDITIONAL_DIRECTIONS_FIELD_NAME).value
  }

  getFormattedLocation (): string {
    let result = ''
    if (this.location && typeof this.location.latitude === 'number' && typeof this.location.longitude === 'number') {
      result = `${this.location.latitude.toFixed(LATLONG_FORMAT_DPS)}N/${this.location.longitude.toFixed(LATLONG_FORMAT_DPS)}E`
    }
    return result
  }

  toJSON (): any {
    return {
      ...this,
      type: undefined
    }
  }

  public static build(dto: any = {}): BaseAddressTemplate {
    return new BaseAddressTemplate(
      dto.addressFormatId,
      (dto.fields || []).map(AddressField.build),
      LocationPoint.build(dto.location || {})
    )
  }
}
