import Logger from 'common/Logger'
import {StateService} from '@uirouter/core'

export default class BaseController {
  public logger: Logger
  public isLoading: boolean = false
  public isLoaded: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    this.logger = new Logger(this.constructor.name)
  }

  /**
   * Override this in your controller
   */
  onSync (): Promise<any> {
    return Promise.resolve()
  }

  sync (): void {
    if (this.isLoading) {
      return
    }
    this.isLoading = true
    this.onSync().then(() => {
      this.isLoading = false
      this.isLoaded = true
      this.$scope.$digest()
    })
  }

  gotoAddEntity (mainState: string, targetState: string): void {
    const params = {}
    if (targetState) {
      params['targetState'] = targetState
    }
    this.$state.go(mainState, params)
  }
}
