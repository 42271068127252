import * as PaymentMethods from 'common/constants/PaymentMethods'

export const ALL_PAYMENTS_OPTIONS = [
  PaymentMethods.CASH,
  PaymentMethods.CARD,
  PaymentMethods.CASH_ON_DELIVERY,
  PaymentMethods.CASH_OFF_SYSTEM,
  PaymentMethods.PAID_OFF_SYSTEM
]

export const PAYMENT_METHOD_SHOW_SETTING_CASH = 'showPaymentCash'
export const PAYMENT_METHOD_SHOW_SETTING_CARD = 'showPaymentPaid'
export const PAYMENT_METHOD_SHOW_SETTING_CASH_ON_DELIVERY = 'showPaymentCashOnDelivery'
export const PAYMENT_METHOD_SHOW_SETTING_CASH_OFF_SYSTEM = 'showPaymentCashOffSystem'
export const PAYMENT_METHOD_SHOW_SETTING_PAID_OFF_SYSTEM = 'showPaymentPaidOffSystem'

export const PAYMENT_METHOD_SHOW_SETTING_MAPPING = {}
PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH] = PAYMENT_METHOD_SHOW_SETTING_CASH
PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CARD] = PAYMENT_METHOD_SHOW_SETTING_CARD
PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH_ON_DELIVERY] = PAYMENT_METHOD_SHOW_SETTING_CASH_ON_DELIVERY
PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH_OFF_SYSTEM] = PAYMENT_METHOD_SHOW_SETTING_CASH_OFF_SYSTEM
PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.PAID_OFF_SYSTEM] = PAYMENT_METHOD_SHOW_SETTING_PAID_OFF_SYSTEM

export const PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING = {}
PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[PaymentMethods.CASH] = [true, false, true]
PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[PaymentMethods.CARD] = [true, false, true]
PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[PaymentMethods.CASH_ON_DELIVERY] = [true, false, true]
PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[PaymentMethods.CASH_OFF_SYSTEM] = [false, false, false]
PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[PaymentMethods.PAID_OFF_SYSTEM] = [false, false, false]

export const PAYMENT_OPTION_NAME = 'payment'
export const DEFAULT_CURRENCY_OPTION_NAME = 'defaultCurrency'

// ========
// REQUESTS
// ========

export const ALL_REQUEST_PAYMENTS_OPTIONS = ALL_PAYMENTS_OPTIONS

export const REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH = 'showRequestsPaymentCash'
export const REQUEST_PAYMENT_METHOD_SHOW_SETTING_CARD = 'showRequestsPaymentPaid'
export const REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH_ON_DELIVERY = 'showRequestsPaymentCashOnDelivery'
export const REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH_OFF_SYSTEM = 'showRequestsPaymentCashOffSystem'

export const REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING = {}
REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH] = REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH
REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CARD] = REQUEST_PAYMENT_METHOD_SHOW_SETTING_CARD
REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH_ON_DELIVERY] = REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH_ON_DELIVERY
REQUEST_PAYMENT_METHOD_SHOW_SETTING_MAPPING[PaymentMethods.CASH_OFF_SYSTEM] = REQUEST_PAYMENT_METHOD_SHOW_SETTING_CASH_OFF_SYSTEM

export const REQUEST_PAYMENT_OPTION_NAME = 'requestsPayment'
export const REQUEST_DEFAULT_CURRENCY_OPTION_NAME = 'requestsDefaultCurrency'
export const REQUEST_DEFAULT_CASH_OPTION_NAME = 'requestsDefaultCash'
export const REQUEST_DEFAULT_PAID_OPTION_NAME = 'requestsDefaultPaid'
