export default {
  defaultCurrencyCode: 'KWD',
  defaultDecimalPointsCount: 2,
  currencyDecimalPointsCount: {
    KWD: 3,
    USD: 2
  },
  defaultCountryCode: 965,
  dateOnlyDateFormat: 'DD/MM/YYYY',
  defaultDateFormat: 'DD/MM/YYYY HH:mm',
  reports: {
    invalidData: '-',
    defaultSortOrder: 'DESC',
    distancePrecision: 2,
    liveOrders: {
      updateIntervalInMs: 30000,
      defaultNumberOfDaysAgo: 1
    },
    ordersByDayGraph: {
      daysLimit: 14,
      updateIntervalInMs: 60000
    },
    areaAnalytics: {
      defaultNumberOfDaysAgo: 3
    }
  },
  pagination: {
    defaultLimit: 20,
    limitOptions: [10, 20, 50, 100],
    maximumNumberOfPages: 7
  },
  nextDayDeliveryPickupSlots: [
    [7, 10],
    [10, 13],
    [13, 16],
    [16, 19],
    [19, 22]
  ],
  defaultNewDeliveryStatus: 'pending'
}
