/* globals require */
/**
 * In most places we are using ngtemplate-loader to handle our templates and put
 * them in cache, but here we need just raw content, so manually switching to
 * html-loader
 */
const template = require('!html-loader!./loading-directive.html')

export default function loadingDirective ($compile) {
  const link = ($scope, $element) => {
    const overlayClass = 'loader--overlay'
    const inProgressClass = 'loader--in-progress'

    const loaderElement = $compile(template)($scope)

    $scope.$watch('loader', () => {
      if ($scope.loader) {
        loaderElement.addClass(inProgressClass)
        $element.addClass(overlayClass)
      } else {
        loaderElement.removeClass(inProgressClass)
        $element.removeClass(overlayClass)
      }
    })

    $scope.$on('$destroy', () => {
      loaderElement.remove()
    })

    $element.append(loaderElement)
  }

  return {
    restrict: 'A',
    scope: {
      loader: '='
    },
    link
  }
}
