import moment from 'moment'
import config from './constants'

const availableTimeSlots = config.nextDayDeliveryPickupSlots

export default class NextDayDeliveryTimeSlot {
  constructor (
    public date: Date,
    public startHours: any,
    public endHours: any
  ) {}

  static buildFromDate (dateObject: Date | string): NextDayDeliveryTimeSlot {
    const safeDateObject = new Date(dateObject)
    const matchingSlot = availableTimeSlots.find((slot: any) => slot[0] === safeDateObject.getHours()) || availableTimeSlots[0]
    return new NextDayDeliveryTimeSlot(safeDateObject, matchingSlot[0], matchingSlot[1])
  }

  static build (dto: any = {}): NextDayDeliveryTimeSlot {
    return new NextDayDeliveryTimeSlot(
      dto.date || new Date(),
      dto.startHours,
      dto.endHours
    )
  }

  getFormattedTimePeriod (): string {
    return this.startHours && this.endHours ? `${this.startHours}:00 - ${this.endHours}:00` : '-'
  }

  getFormattedDate (dateFormat: string = config.dateOnlyDateFormat): string {
    return this.date ? moment(this.date).format(dateFormat) : '-.-.-'
  }

  getFormattedTimeSlot (dateFormat: string = config.dateOnlyDateFormat): string {
    return `${this.getFormattedDate(dateFormat)} ${this.getFormattedTimePeriod()}`
  }
}
