import envConfig from './envConfig'
import Logger from 'common/Logger'

const logger = new Logger('Endpoints')

export function getApiPathForKey (key) {
  const endpointPath = envConfig.api && envConfig.api.paths && envConfig.api.paths[key]
  if (!endpointPath) {
    logger.warn(`No endpoint path provided in environment config for key: ${key}`)
    return '/'
  } else {
    return endpointPath
  }
}

function getHeadersForKey (key) {
  return envConfig.api && envConfig.api.headers && envConfig.api.headers[key] || {}
}

function getEndpointDescriptorFor (entityName) {
  return {
    entityName: entityName,
    endpointPath: getApiPathForKey(entityName),
    endpointHeaders: getHeadersForKey(entityName)
  }
}

export default {
  ADDRESS: getEndpointDescriptorFor('address'),
  ADDRESSES_LOOKUP: getEndpointDescriptorFor('addressesLookup'),
  ADDRESS_FIELD_VALUES: getEndpointDescriptorFor('addressFieldValues'),
  ADDRESS_FORMAT: getEndpointDescriptorFor('addressFormat'),
  ADDRESS_FORMATS: getEndpointDescriptorFor('addressFormats'),
  ADDRESS_GEOCODE_FROM_ADDRESS: getEndpointDescriptorFor('addressGeocodeFromAddress'),
  ADDRESS_GEOCODE_FROM_LOCATION: getEndpointDescriptorFor('addressGeocodeFromLocation'),
  ADDRESS_AREAS: getEndpointDescriptorFor('addressAreas'),
  AREA: getEndpointDescriptorFor('area'),
  AREAS: getEndpointDescriptorFor('areas'),
  AREA_ANALYTICS_REPORT_BY_AREA: getEndpointDescriptorFor('areaAnalyticsReportByArea'),
  AREA_ANALYTICS_REPORT_BY_BRANCH: getEndpointDescriptorFor('areaAnalyticsReportByBranch'),
  AUTH: getEndpointDescriptorFor('auth'),
  AUTH_BASIC: getEndpointDescriptorFor('authBasic'),
  AUTH_TOKEN: getEndpointDescriptorFor('authToken'),
  BRANCH: getEndpointDescriptorFor('branch'),
  BRANCH_ALL_LANGUAGES: getEndpointDescriptorFor('branchAllLanguages'),
  BRANCH_BALANCE_HISTORY: getEndpointDescriptorFor('branchBalanceHistory'),
  BUSINESS: getEndpointDescriptorFor('business'),
  BUSINESS_ALL_LANGUAGES: getEndpointDescriptorFor('businessAllLanguages'),
  BUSINESS_BRANCH: getEndpointDescriptorFor('businessBranch'),
  BUSINESS_BRANCH_BALANCES: getEndpointDescriptorFor('businessBranchBalances'),
  BUSINESS_BRANCH_SETTINGS: getEndpointDescriptorFor('businessBranchSettings'),
  BUSINESS_BRANCH_STOREFRONT_SETTINGS_ALL_LANGUAGES: getEndpointDescriptorFor('businessBranchStorefrontSettingsAllLanguages'),
  BUSINESS_BRANCH_STOREFRONT_SETTINGS: getEndpointDescriptorFor('businessBranchStorefrontSettings'),
  BUSINESS_BRANCH_AREAS_STOREFRONT_SETTINGS: getEndpointDescriptorFor('businessBranchAreasStorefrontSettings'),
  BUSINESS_REGISTRATION_DATA_ALL_LANGUAGES: getEndpointDescriptorFor('businessRegistrationDataAllLanguages'),
  BUSINESS_REGISTRATION_DATA: getEndpointDescriptorFor('businessRegistrationData'),
  BUSINESSES: getEndpointDescriptorFor('businesses'),
  BUSINESSES_SETTINGS: getEndpointDescriptorFor('businessSettings'),
  BUSINESS_STOREFRONT_SETTINGS: getEndpointDescriptorFor('businessStorefrontSettings'),
  BUSINESS_STOREFRONT_SETTINGS_ALL_LANGUAGES: getEndpointDescriptorFor('businessStorefrontSettingsAllLanguages'),
  STOREFRONT_ADDRESS_AREAS: getEndpointDescriptorFor('storefrontAddressAreas'),
  STOREFRONT_BUSINESS_DETAILS: getEndpointDescriptorFor('storefrontBusinessDetails'),
  STOREFRONT_BUSINESSES_WITH_SUBSCRIPTIONS: getEndpointDescriptorFor('storefrontBusinessesWithSubscriptions'),
  STOREFRONT_CHARGE_BY_SUBSCRIPTION_PLAN: getEndpointDescriptorFor('storefrontChargeBySubscriptionPlan'),
  STOREFRONT_SUBSCRIPTION: getEndpointDescriptorFor('storefrontSubscription'),
  STOREFRONT_TRANSACTIONS: getEndpointDescriptorFor('storefrontTransactions'),
  STOREFRONT_CATEGORIES: getEndpointDescriptorFor('storefrontCategories'),
  STOREFRONT_CATEGORY_ALL_LANGUAGES: getEndpointDescriptorFor('storefrontCategoryAllLanguages'),
  STOREFRONT_CATEGORY: getEndpointDescriptorFor('storefrontCategory'),
  STOREFRONT_PRODUCTS: getEndpointDescriptorFor('storefrontProducts'),
  STOREFRONT_PRODUCT_ALL_LANGUAGES: getEndpointDescriptorFor('storefrontProductAllLanguages'),
  STOREFRONT_PRODUCT: getEndpointDescriptorFor('storefrontProduct'),
  STOREFRONT_PRODUCT_AVAILABILITY: getEndpointDescriptorFor('storefrontProductAvailability'),
  STOREFRONT_PRODUCT_SKUS: getEndpointDescriptorFor('storefrontProductSKU'),
  STOREFRONT_CATEGORIES_ORDER: getEndpointDescriptorFor('storefrontCategoriesOrder'),
  STOREFRONT_PRODUCTS_ORDER: getEndpointDescriptorFor('storefrontProductsOrder'),
  STOREFRONT_TAGS: getEndpointDescriptorFor('storefrontTags'),
  STOREFRONT_TAG: getEndpointDescriptorFor('storefrontTag'),
  STOREFRONT_DOMAINS: getEndpointDescriptorFor('storefrontDomains'),
  STOREFRONT_ORDER_HISTORY: getEndpointDescriptorFor('storefrontOrderHistory'),
  STOREFRONT_ORDERS: getEndpointDescriptorFor('storefrontOrders'),
  STOREFRONT_ORDER: getEndpointDescriptorFor('storefrontOrder'),
  STOREFRONT_ORDER_REFUND: getEndpointDescriptorFor('storefrontOrderRefund'),
  STOREFRONT_ACCEPT_ORDER: getEndpointDescriptorFor('storefrontAcceptOrder'),
  STOREFRONT_ORDER_DELIVERY: getEndpointDescriptorFor('storefrontOrderDelivery'),
  STOREFRONT_UPDATE_ORDER_STATUS: getEndpointDescriptorFor('storefrontUpdateOrderStatus'),
  STOREFRONT_EARNINGS_SUMMARY: getEndpointDescriptorFor('storefrontEarningsSummary'),
  STOREFRONT_PAYOUT_REQUEST: getEndpointDescriptorFor('storefrontPayoutRequest'),
  STOREFRONT_SERVED_AREAS: getEndpointDescriptorFor('storefrontServedAreas'),
  STOREFRONT_PROMO_CODES: getEndpointDescriptorFor('storefrontPromoCodes'),
  STOREFRONT_PROMO_CODE: getEndpointDescriptorFor('storefrontPromoCode'),
  CONSUMER: getEndpointDescriptorFor('consumer'),
  CONSUMER_ADDRESSES: getEndpointDescriptorFor('consumerAddresses'),
  CONSUMER_ADDRESS: getEndpointDescriptorFor('consumerAddress'),
  CONSUMER_BALANCE_HISTORY: getEndpointDescriptorFor('consumerBalanceHistory'),
  CONSUMERS: getEndpointDescriptorFor('consumers'),
  CONSUMERS_SEARCH: getEndpointDescriptorFor('consumersSearch'),
  CONSUMERS_SEARCH_OR_CREATE: getEndpointDescriptorFor('consumersSearchOrCreate'),
  COUNTRIES: getEndpointDescriptorFor('countries'),
  CURRENCIES: getEndpointDescriptorFor('currencies'),
  DELIVERY: getEndpointDescriptorFor('delivery'),
  DELIVERY_GET: getEndpointDescriptorFor('deliveryGet'),
  DELIVERY_NEXT_DAY: getEndpointDescriptorFor('deliveryNextDay'),
  DELIVERY_AVAILABLE_FLEETS: getEndpointDescriptorFor('deliveryAvailableFleets'),
  DELIVERY_NEXT_DAY_AVAILABLE_FLEETS: getEndpointDescriptorFor('deliveryNextDayAvailableFleets'),
  DELIVERY_NEXT_DAY_AVAILABLE_DRIVERS: getEndpointDescriptorFor('deliveryNextDayAvailableDrivers'),
  DELIVERY_NEXT_DAY_LIST: getEndpointDescriptorFor('deliveryNextDayList'),
  DELIVERY_NEXT_DAY_UNALLOCATED_LINKED: getEndpointDescriptorFor('deliveryNextDayUnallocatedLinked'),
  DELIVERY_BATCHING: getEndpointDescriptorFor('deliveryBatching'),
  DELIVERY_BATCHING_TYPES: getEndpointDescriptorFor('deliveryBatchingTypes'),
  DELIVERY_QUOTE: getEndpointDescriptorFor('deliveryQuote'),
  DELIVERY_RECEIPT_TEMPLATE: getEndpointDescriptorFor('deliveryReceiptTemplate'),
  DELIVERY_STATUS: getEndpointDescriptorFor('deliveryStatus'),
  DELIVERY_TRACKING: getEndpointDescriptorFor('deliveryTracking'),
  DELIVERY_TRACKING_SETTINGS: getEndpointDescriptorFor('deliveryTrackingSettings'),
  DELIVERY_TRACKING_VERIFY_ADDRESS: getEndpointDescriptorFor('deliveryTrackingVerifyAddress'),
  DELIVERY_SLA_TYPES: getEndpointDescriptorFor('deliverySlaTypes'),
  DELIVERY_STATE_APPLICABILITY_TYPES: getEndpointDescriptorFor('deliveryStateApplicabilityTypes'),
  DELIVERY_OVERRIDE_STATUS: getEndpointDescriptorFor('deliveryOverrideStatus'),
  DELIVERY_TRANSFER_AVAILABLE_DRIVERS: getEndpointDescriptorFor('deliveryTransferAvailableDrivers'),
  DELIVERY_TRANSFER_TO_DRIVER: getEndpointDescriptorFor('deliveryTransferToDriver'),
  DELIVERIES_TRANSFER_TO_DRIVER: getEndpointDescriptorFor('deliveriesTransferToDriver'),
  DELIVERIES_KANBAN: getEndpointDescriptorFor('deliveriesKanban'),
  DELIVERIES_AVAILABLE_FOR_BATCHING: getEndpointDescriptorFor('deliveriesAvailableForBatching'),
  DRIVER: getEndpointDescriptorFor('driver'),
  DRIVER_CASH: getEndpointDescriptorFor('driverCash'),
  DRIVER_CASH_BALANCE: getEndpointDescriptorFor('driverCashBalance'),
  DRIVER_FIND_BY_USER: getEndpointDescriptorFor('driverFindByUser'),
  DRIVERS: getEndpointDescriptorFor('drivers'),
  DRIVER_VERIFIED: getEndpointDescriptorFor('driverVerified'),
  DRIVER_VERIFICATION_CODE: getEndpointDescriptorFor('driverVerificationCode'),
  DRIVER_VERIFICATION_CODE_SEND: getEndpointDescriptorFor('driverVerificationCodeSend'),
  FILE: getEndpointDescriptorFor('file'),
  FLEET: getEndpointDescriptorFor('fleet'),
  FLEET_ALL_LANGUAGES: getEndpointDescriptorFor('fleetAllLanguages'),
  FLEET_GLOBAL: getEndpointDescriptorFor('fleetGlobal'),
  FLEETS: getEndpointDescriptorFor('fleets'),
  FLEET_TARIFF: getEndpointDescriptorFor('fleetTariff'),
  FLEET_TARIFFS: getEndpointDescriptorFor('fleetTariffs'),
  FLEET_TARIFFS_ORDER: getEndpointDescriptorFor('fleetTariffsOrder'),
  FLEET_TRANSACTIONS_HISTORY: getEndpointDescriptorFor('fleetHistory'),
  FLEET_SETTINGS: getEndpointDescriptorFor('fleetSettings'),
  IMAGE: getEndpointDescriptorFor('image'),
  JOB: getEndpointDescriptorFor('job'),
  KANBAN: getEndpointDescriptorFor('kanban'),
  LOCALE: getEndpointDescriptorFor('locale'),
  PACKAGE_TYPES: getEndpointDescriptorFor('packageTypes'),
  PROMO_CODES: getEndpointDescriptorFor('promoCodes'),
  PROMO_CODE: getEndpointDescriptorFor('promoCode'),
  RECEIPT_TEMPLATES: getEndpointDescriptorFor('receiptTemplates'),
  RECEIPT_TEMPLATE: getEndpointDescriptorFor('receiptTemplate'),
  REPORTS_DRIVER_ANALYTICS: getEndpointDescriptorFor('reportDriverAnalytics'),
  REPORTS_LIVE_ORDERS: getEndpointDescriptorFor('reportLiveOrders'),
  REPORTS_LIVE_ORDERS_DAILY: getEndpointDescriptorFor('reportLiveOrdersDaily'),
  ROLES: getEndpointDescriptorFor('roles'),
  ROLES_ASSIGNABLE: getEndpointDescriptorFor('rolesAssignable'),
  ROLES_SEARCH: getEndpointDescriptorFor('rolesSearch'),
  SERVICE: getEndpointDescriptorFor('service'),
  SERVICES: getEndpointDescriptorFor('services'),
  SIGNUP: getEndpointDescriptorFor('signup'),
  SMS_TEMPLATES: getEndpointDescriptorFor('smsTemplates'),
  SUBSCRIPTION_PLANS: getEndpointDescriptorFor('subscriptionPlans'),
  SCHEDULE: getEndpointDescriptorFor('schedule'),
  SCHEDULES: getEndpointDescriptorFor('schedules'),
  TARIFF_PERIOD: getEndpointDescriptorFor('tariffPeriod'),
  TARIFF_PERIODS: getEndpointDescriptorFor('tariffPeriods'),
  TENANT_SETTINGS: getEndpointDescriptorFor('tenantSettings'),
  TIERS: getEndpointDescriptorFor('tiers'),
  UNITS_DISTANCE: getEndpointDescriptorFor('unitsDistance'),
  USER: getEndpointDescriptorFor('user'),
  USER_BALANCE: getEndpointDescriptorFor('userBalance'),
  USER_TRANSACTIONS_HISTORY: getEndpointDescriptorFor('userTransactionsHistory'),
  USER_CLAIM: getEndpointDescriptorFor('userClaim'),
  USER_CHANGE_PASSWORD: getEndpointDescriptorFor('userChangePassword'),
  USER_CONFIRM_CREDENTIAL: getEndpointDescriptorFor('userConfirmCredential'),
  USER_MANAGEMENT_TEAM_MEMBER: getEndpointDescriptorFor('userManagementTeamMember'),
  USER_MANAGEMENT_TEAM_MEMBERS: getEndpointDescriptorFor('userManagementTeamMembers'),
  USER_REQUEST_RESET_PASSWORD: getEndpointDescriptorFor('userRequestResetPassword'),
  USER_RESET_PASSWORD: getEndpointDescriptorFor('userResetPassword'),
  USER_RESEND_CODE: getEndpointDescriptorFor('userResendCode'),
  USERS: getEndpointDescriptorFor('users'),
  USER_SUBSCRIPTION: getEndpointDescriptorFor('userSubscription'),
  PAYMENT_STRIPE: getEndpointDescriptorFor('paymentStripe'),
  PAYMENT_TAP: getEndpointDescriptorFor('paymentTap'),
  PAYMENT_KNET: getEndpointDescriptorFor('paymentKnet'),
  PAYMENT_PROVIDER: getEndpointDescriptorFor('paymentProviders'),
  PAYMENT_STRIPE_CARDS: getEndpointDescriptorFor('paymentStripeCards'),
  PAYMENT_CYBERSOURCE: getEndpointDescriptorFor('paymentCybersource'),
  REQUEST: getEndpointDescriptorFor('request'),
  REQUEST_ADDRESS: getEndpointDescriptorFor('requestAddress'),
  REQUEST_CHECK: getEndpointDescriptorFor('requestCheck'),
  REQUESTS_ACTION_REQUIRED: getEndpointDescriptorFor('requestsActionRequired'),
  REQUESTS_CONFIRMED: getEndpointDescriptorFor('requestsConfirmed'),
  RIDE: getEndpointDescriptorFor('ride'),
  RIDE_AVAILABLE_FLEETS: getEndpointDescriptorFor('rideAvailableFleets'),
  VOUCHER: getEndpointDescriptorFor('voucher'),
  VOUCHER_APPLY: getEndpointDescriptorFor('voucherApply'),
  VOUCHER_VALIDATE: getEndpointDescriptorFor('voucherValidate'),
  WITHDRAWAL_REQUEST: getEndpointDescriptorFor('withdrawalRequest'),
  ISSUES: getEndpointDescriptorFor('issues'),
  ISSUE_RESOLVE: getEndpointDescriptorFor('issueResolve'),
  ISSUES_RESOLVED: getEndpointDescriptorFor('issuesResolved'),
  PLACE: getEndpointDescriptorFor('place'),
  PLACE_ALL_LANGUAGES: getEndpointDescriptorFor('placeAllLanguages'),
  PLACES: getEndpointDescriptorFor('places'),
  PLACE_CATEGORIES: getEndpointDescriptorFor('placeCategories'),
  BRANDED_DOMAINS: getEndpointDescriptorFor('brandedDomains'),
  DELIVERY_SCHEDULE_APPLICABILITY_TYPES: getEndpointDescriptorFor('deliveryScheduleApplicabilityTypes'),
  TARIFF_SCHEDULED_RIDE_DEFAULTS: getEndpointDescriptorFor('tariffScheduledRideDefaults'),
  SCHEDULED_DELIVERIES: getEndpointDescriptorFor('scheduledDeliveries'),
  BANKS: getEndpointDescriptorFor('banks'),
  PRICE_SURGES_SETTINGS: getEndpointDescriptorFor('priceSurgesSettings'),
  PRICE_SURGES_ORDER: getEndpointDescriptorFor('priceSurgesOrder'),
  PRICE_SURGES: getEndpointDescriptorFor('priceSurges'),
  PRICE_SURGE: getEndpointDescriptorFor('priceSurge'),
  PRICE_SURGE_STATUS: getEndpointDescriptorFor('priceSurgeStatus'),
  ENTERPRICE_WEB_USER: getEndpointDescriptorFor('enterpriceWebUser')
}
