// Approach taken from https://davidwalsh.name/pubsub-javascript

class PubSub {
  private topics: any = {}

  constructor () {}

  subscribe (topic: string, listener: () => void): IPubSubscription {
    // Create the topic's object if not yet created
    if (!this.topics.hasOwnProperty(topic)) {
      this.topics[topic] = []
    }

    // Add the listener to queue
    const index = this.topics[topic].push(listener) - 1

    // Provide handle back for removal of topic
    return {
      remove: (): void => {
        delete this.topics[topic][index]
      }
    }
  }

  publish (topic: string, info?: any): void {
    // If the topic doesn't exist, or there's no listeners in queue, just leave
    if (this.topics.hasOwnProperty(topic)) {
      // Cycle through topics queue, fire!
      this.topics[topic].forEach((callback: (info: any) => void) => {
        callback(info !== undefined ? info : {})
      })
    }
  }
}

export default PubSub
