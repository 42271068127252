/**
 * Tries to get saved value from localStorage.
 * If the value is complex it will parse it.
 */
export const getItemFromLocalStorage = (keyName: string, fallbackValue: any = null): any => {
  let result: any
  let savedValue: string
  try {
    savedValue = (typeof localStorage !== 'undefined') ? localStorage.getItem(keyName) : null
    if (savedValue === null) {
      // no saved value in local storage
      result = fallbackValue
    } else if (savedValue[0] === '[' || savedValue[0] === '{') {
      // stored value is an object or array
      result = JSON.parse(savedValue)
    } else {
      // simple value (note always returns string)
      result = savedValue
    }
  } catch (err) {
    console.error(err, savedValue)
    result = fallbackValue
  }

  return result
}
