import * as moment from 'moment'
import Logger from 'common/Logger'

const logger = new Logger('Date Utils')

export default class DateUtils {
  static getFormattedDate (date: Date, format: string = 'YYYY-MM-DD'): string {
    return moment(date).locale('en').format(format)
  }

  static isValidDateObject (date: Date): boolean {
    return date && date.getMonth && typeof date.getMonth === 'function' && !isNaN(date.getMonth())
  }

  static makeSafeDateFromString (isoDateString: any): Date {
    const dateObject = isoDateString ? new Date(isoDateString) : null

    if (!DateUtils.isValidDateObject(dateObject)) {
      logger.error('Invalid ISO-8601 string given!')
    }

    return dateObject
  }

  static getStartOfDay (date: Date): Date {
    const newDate: Date = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  }

  static getEndOfDay (date: Date): Date {
    const MAX_HOURS = 23
    const MAX_MINUTES_OR_SECONDS = 59
    const MAX_MILLISECONDS = 999
    const newDate: Date = new Date(date)
    newDate.setHours(MAX_HOURS)
    newDate.setMinutes(MAX_MINUTES_OR_SECONDS)
    newDate.setSeconds(MAX_MINUTES_OR_SECONDS)
    newDate.setMilliseconds(MAX_MILLISECONDS)
    return newDate
  }

  static areTheSameDates (dateA: Date, dateB: Date): boolean {
    return dateA && dateB && (
      dateA.getDate() === dateB.getDate()
      && dateA.getMonth() === dateB.getMonth()
      && dateA.getFullYear() === dateB.getFullYear()
    )
  }

  static isDateNotLater (dateA: Date, dateB: Date): boolean {
    const dateWithoutTimeA: Date = DateUtils.getStartOfDay(dateA)
    const dateWithoutTimeB: Date = DateUtils.getStartOfDay(dateB)
    return dateWithoutTimeB.getTime() - dateWithoutTimeA.getTime() >= 0
  }

  static isDateNotEarlier (dateA: Date, dateB: Date): boolean {
    const dateWithoutTimeA: Date = DateUtils.getStartOfDay(dateA)
    const dateWithoutTimeB: Date = DateUtils.getStartOfDay(dateB)
    return dateWithoutTimeA.getTime() - dateWithoutTimeB.getTime() >= 0
  }

  static getIsToday (timestamp: number): boolean {
    const timestampMoment = moment(timestamp || null)
    return timestampMoment.isValid() && timestampMoment.isSame(new Date(), 'day')
  }

  static getIsTomorrow (timestamp: number): boolean {
    const timestampMoment = moment(timestamp || null)
    const tomorrowMoment = moment().add(1, 'days')
    return timestampMoment.isValid() && timestampMoment.isSame(tomorrowMoment, 'day')
  }
}
