import localEnvConfig from 'common/envConfig'

export class BusinessDocument {
  public issuingCountryCode: string = localEnvConfig.defaultCountry

  constructor(
    public number: string = '',
    public issuingDate: string = '',
    public expiryDate: string = '',
    public files: string[] = []
  ) {}

  public toJSON(): any {
    return {
      ...this,
      issuingDate: this.issuingDate || null,
      expiryDate: this.expiryDate || null
    }
  }

  public static build(data: any = {}): BusinessDocument {
    return new BusinessDocument(
      data.number,
      data.issuingDate,
      data.expiryDate,
      data.files
    )
  }
}
