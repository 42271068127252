import find from 'lodash-es/find'
import uniqBy from 'lodash-es/uniqBy'
import Address from './Address'
import PhoneNumber from './PhoneNumber'

export default class Consumer {
  constructor (
    public id: string,
    public firstName: string,
    public lastName: string,
    public countryDiallingCode: string,
    public phoneNumber: string,
    public addresses: Address[],
    public balanceAccountId: string
  ) {}

  static build (consumerDTO: any = {}): Consumer {
    let addresses: any[] = consumerDTO.addresses ? uniqBy(consumerDTO.addresses, 'id') : []
    addresses = addresses.map((address: any) => Address.build(address))

    return new Consumer(
      consumerDTO.id || null,
      consumerDTO.firstName || '',
      consumerDTO.lastName || '',
      consumerDTO.countryDiallingCode || '',
      consumerDTO.phoneNumber || '',
      addresses,
      consumerDTO.balanceAccountId || ''
      )
  }

  toJSON (): any {
    const addresses = this.addresses.map((address: Address) => {
      return {
        addressId: address.id,
        name: address.name
      }
    })

    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      countryDiallingCode: this.countryDiallingCode,
      phoneNumber: this.phoneNumber,
      addresses
    }
  }

  getHasAddress (addressId: string): boolean {
    return Array.isArray(this.addresses) && !!find(this.addresses, {id: addressId})
  }

  get fullName (): string {
    return `${this.firstName} ${this.lastName}`
  }

  getFormattedPhone (): string {
    return PhoneNumber.build(this).getFormattedPhone()
  }
}
