import config from './constants'

export default class Cost {
  constructor (
    public amount: number,
    public currencyCode: string
  ) {}

  get value (): number {
    return this.amount
  }

  set value (value: number) {
    this.amount = value
  }

  static build (dto: any = {}, forcePositive: boolean = false): Cost {
    const amount = parseFloat(dto.amount || dto.value || 0)
    const currencyCode = dto.currencyCode || config.defaultCurrencyCode

    return new Cost(forcePositive ? Math.abs(amount) : amount, currencyCode)
  }

  static getDecimalPointsCountForCurrency (currencyCode: string): number {
    return (currencyCode && config.currencyDecimalPointsCount[currencyCode.toUpperCase()]) || config.defaultDecimalPointsCount
  }

  static getAvailableCurrencyCodes (): string[] {
    return Object.keys(config.currencyDecimalPointsCount)
  }

  toJSON (): {value: number, currencyCode: string} {
    return {
      value: this.amount || 0,
      currencyCode: this.currencyCode || config.defaultCurrencyCode
    }
  }

  getFormattedCost (noCurrencyCode?: boolean): string {
    if (!isNaN(this.amount)) {
      if (typeof this.amount === 'string') {
        this.amount = parseFloat(this.amount)
      }
      const formattedAmount = this.amount.toFixed(Cost.getDecimalPointsCountForCurrency(this.currencyCode))
      return `${(!noCurrencyCode) ? this.currencyCode.toUpperCase() + ' ' : ''}${formattedAmount}`
    } else {
      return '-'
    }
  }
}
