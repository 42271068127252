import localeHelper from 'common/localeHelper'

export default function translateFilter () {
  function translate (keyInput, preventArabicTranslation = false) {
    const languageToTranslateTo = preventArabicTranslation ? 'en' : localeHelper.getLanguage()
    return localeHelper.lookupTranslationKey(keyInput, languageToTranslateTo)
  }
  // TODO FIXME
  translate.$stateful = true

  return translate
}
