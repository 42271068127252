export default class Vehicle {
  constructor (
    public make: string = '',
    public model: string = '',
    public colour: string = '',
    public registrationPlate: string = '',
    public vin: string = ''
  ) {}

  get vehicleDescription (): string {
    return `${this.make} ${this.model} - ${this.registrationPlate}`
  }

  static build (dto: any = {}): Vehicle {
    return new Vehicle(
      dto.make || '',
      dto.model || '',
      dto.colour || '',
      dto.registrationPlate || '',
      dto.vin || ''
    )
  }
}
