/* globals envConfig:false */
// Collect env config from either the global namespace, or provide the empty object instead
let localEnvConfig
try { // Use try-catch to handle node throwing on references to undefined symbols
  localEnvConfig = envConfig
} catch (e) {
  localEnvConfig = {}
}

export default localEnvConfig
