export const enum PromocodeStatusesType {
  Applied = 'Applied',
  NotApplicable = 'NotApplicable',
  OverTotalLimit = 'OverTotalLimit',
  OverPersonalLimit = 'OverPersonalLimit',
  Expired = 'Expired',
  Invalid = 'Invalid',
}

export const PROMOCODE_STATUSES = {
  APPLIED: PromocodeStatusesType.Applied,
  NOT_APPLICABLE: PromocodeStatusesType.NotApplicable,
  OVER_TOTAL_LIMIT: PromocodeStatusesType.OverTotalLimit,
  OVER_PERSONAL_LIMIT: PromocodeStatusesType.OverPersonalLimit,
  EXPIRED: PromocodeStatusesType.Expired,
  INVALID: PromocodeStatusesType.Invalid,
}
