import * as AuthenticationStatuses from 'common/constants/AuthenticationStatuses'
import * as DriverStatuses from 'common/constants/DriverStatuses'
import * as DriverAppConnectionStatuses from 'common/constants/DriverAppConnectionStatuses'
import Vehicle from 'data/domain-objects/Vehicle'
import DeprecatedLastKnownLocation from 'data/domain-objects/DeprecatedLastKnownLocation'

export default class Driver {
  constructor (
    public id: string = '',
    public firstName: string = '',
    public lastName: string =  '',
    public fullName: string = '',
    public email: string = '',
    public phoneNumber: string = '',
    public photoUrl: string = '',
    public activeJob: string = '',
    public authStatus: string = AuthenticationStatuses.LOGGED_OUT,
    public status: string = DriverStatuses.OFFLINE,
    public connectionStatus: string = DriverAppConnectionStatuses.DISCONNECTED,
    public cashBalanceAccountId: string = '',
    public comments: string = '',
    public countryDiallingCode: string = '',
    public fleets: string[] = [],
    public schedules: string[] = [],
    public lastKnownLocation: DeprecatedLastKnownLocation = new DeprecatedLastKnownLocation(),
    public licenseNumber: string = '',
    public onboarded: boolean = false,
    public password: string = '',
    public rating: number = 0,
    public vehicle: Vehicle = new Vehicle()
  ) {}

  get isOnline (): boolean {
    return this.status === DriverStatuses.ONLINE
  }

  get isOffline (): boolean {
    return this.status === DriverStatuses.OFFLINE
  }

  get isWorking (): boolean {
    return this.status === DriverStatuses.WORKING
  }

  get isReady (): boolean {
    return this.status === DriverStatuses.READY
  }

  get phone (): string {
    return `+${this.countryDiallingCode}${this.phoneNumber}`
  }

  get phoneLink (): string {
    return `tel:+${this.countryDiallingCode}${this.phoneNumber}`
  }

  get avatarCssUrl (): string {
    return this.photoUrl ? `url('${this.photoUrl}/120x120')` : ''
  }

  static build (dto: any = {}): Driver {
    return new Driver(
      dto.id || dto.driverId || '',
      dto.firstName || '',
      dto.lastName || '',
      dto.fullName || '',
      dto.email || '',
      dto.phoneNumber || '',
      dto.photoUrl || '',
      dto.activeJob || '',
      dto.authStatus || '',
      dto.status || '',
      dto.connectionStatus || '',
      dto.cashBalanceAccountId || '',
      dto.comments || '',
      dto.countryDiallingCode || '',
      dto.fleets || '',
      dto.schedules || '',
      DeprecatedLastKnownLocation.build(dto.lastKnownLocation || {}),
      dto.licenseNumber || '',
      dto.onboarded || '',
      dto.password || '',
      dto.rating || 0,
      Vehicle.build(dto.vehicle || {})
    )
  }
}
