import * as moment from 'moment'
import EntityUtils from 'common/utils/entityUtils'
import {DeliveryStatusesType} from 'common/constants/DeliveryStatuses'
import {DELIVERY_TYPES} from 'data/collections/deliveriesCollection'
import NextDayDeliveryTimeSlot from './NextDayDeliveryTimeSlot'
import PartyDescriptor from './PartyDescriptor'
import DeliveryEvent from './DeliveryEvent'
import PaymentData from './PaymentData'
import Driver from './Driver'
import Fleet from './Fleet'

export default class Delivery {
  public driverId: string = ''
  public fleetId: string  = ''
  public pickupTimeSlot: NextDayDeliveryTimeSlot
  public dropoffTimeSlot: NextDayDeliveryTimeSlot

  constructor (
    public id: string = '',
    public ref: string = '',
    public type: string = DELIVERY_TYPES.DELIVERY,
    public status: DeliveryStatusesType,
    public statusUpdatedAt: Date,
    public createdAt: Date,
    public updatedAt: Date,
    public pickupTime: string,
    public etaPickup: string,
    public etaPickupInitial: string,
    public etaDropoff: string,
    public etaDropoffInitial: string,
    public driver: Driver,
    public fleet: Fleet,
    public sender: object,
    public recipient: object,
    public originatingConsumer: any,
    public payment: PaymentData,
    public canBatch: boolean = false,
    public balanceAccountId: string,
    public events: DeliveryEvent[] = [],
    public packages: any[] = [],
    public permissions: any[] = [],
    public proofOfDelivery: object = {},
    public quote: object = {},
    public isInbound: boolean = false,
    public isOutbound: boolean = false,
    public area: string = ''
  ) {
    this.statusUpdatedAt = statusUpdatedAt ? new Date(statusUpdatedAt) : statusUpdatedAt
    this.createdAt = createdAt ? new Date(createdAt) : createdAt
    this.updatedAt = updatedAt ? new Date(updatedAt) : updatedAt
    this.driver = Driver.build(driver)
    this.fleet = Fleet.build(fleet)
    this.sender = PartyDescriptor.build(sender || {})
    this.recipient = PartyDescriptor.build(recipient || {})
    this.driverId = this.driver.id
    this.fleetId = this.fleet.id
    this.events = Array.isArray(events) ? events.map(DeliveryEvent.build) : []
    this.isInbound = isInbound || !!ref.match(/^.*-IN$/)
    this.isOutbound = isOutbound || !!ref.match(/^.*-OUT$/)
    this.payment = PaymentData.build(payment)

    if (this.isRelatedToNextDay && this.pickupTime) {
      const dropoffMoment = moment(this.pickupTime).add(1, 'days')
      this.pickupTimeSlot = NextDayDeliveryTimeSlot.build(this.pickupTime)
      this.dropoffTimeSlot = NextDayDeliveryTimeSlot.build(dropoffMoment.toDate())
    }
  }

  static buildFromNextDayDeliveryDTO(nextDayDeliveryDTO: any = {}): Delivery {
    const delivery = Delivery.build(nextDayDeliveryDTO)
    delivery.pos = nextDayDeliveryDTO.pos
    return delivery
  }

  static build (dto: any = {}): Delivery {

    return new Delivery(
      dto.id,
      dto.ref,
      dto.type,
      dto.status,
      dto.statusUpdatedAt,
      dto.createdAt,
      dto.updatedAt,
      dto.pickupTime,
      dto.etaPickup,
      dto.etaPickupInitial,
      dto.etaDropoff,
      dto.etaDropoffInitial,
      EntityUtils.castInstanceFromItselfOrId(dto.driver, dto.driverId),
      EntityUtils.castInstanceFromItselfOrId(dto.fleet, dto.fleetId),
      dto.sender,
      dto.recipient,
      dto.originatingConsumer,
      dto.payment,
      dto.canBatch,
      dto.balanceAccountId,
      dto.events,
      dto.packages,
      dto.permissions,
      dto.proofOfDelivery,
      dto.quote,
      dto.isInbound,
      dto.isOutbound,
      dto.area
    )
  }

  get isNextDay (): boolean {
    return this.type === DELIVERY_TYPES.NEXT_DAY
  }

  get isRelatedToNextDay (): boolean {
    return this.isNextDay || this.isInbound || this.isOutbound
  }

  get pos (): string {
    return this.payment.posReference
  }

  set pos (posValue: string) {
    this.payment.posReference = posValue
  }
}
