import kebabCase from 'lodash-es/kebabCase'
import Logger from 'common/Logger'
import {StateProvider, Ng1StateDeclaration} from '@uirouter/angularjs'

const logger = new Logger('declareState')

export interface IStateConfig extends Ng1StateDeclaration {
  stateName?: string,
  stateKey?: string,
  idParam?: string,
  componentName?: string,
  requiresPermission?: string
}

const getParamsGivenConfig = (config: IStateConfig): string[] => {
  let params = ['token'] // Every state has an optional token param
  if (Array.isArray(config.params)) {
    params = params.concat(config.params)
  }
  return params
}

const buildParamString = (idParam: string, queryParams: string[]): string => {
  const idParamString = idParam ? `/{${idParam}}` : ''
  let paramString = ''
  if (Array.isArray(queryParams)) {
    paramString = '?' + queryParams.join('&')
  }

  return `${idParamString}${paramString}`
}

const getContentConfig = (config: IStateConfig): any => {
  if (config.redirectTo) { // Redirect states have no view content
    return {}
  } else if (config.componentName) {

    const kebabCaseComponentName = kebabCase(config.componentName)

    return {
      template: `<${kebabCaseComponentName}></${kebabCaseComponentName}>`
    }

  } else {

    return {
      controller: config.controller,
      templateUrl: config.template,
      controllerAs: null
    }
  }
}

const declareState = ($stateProvider: StateProvider, config: IStateConfig): void => {
  const state = `${config.parent}.${config.stateName}`
  const baseState = state.split('.')[0]
  const queryParams = getParamsGivenConfig(config)
  const paramString = buildParamString(config.idParam, queryParams)
  const contentConfig = getContentConfig(config)
  const data: any = config.data || {}

  if (config.requiresPermission) { // Attach permissions to data so it's inherited by child states.
    data.requiresPermission = config.requiresPermission
  }

  /**
   * I'm tired of wasting time trying to debug why things doesn't work
   * either I mistyped componentName or passed reference to controller instead of string
   */
  if ((config.template && !config.controller) || (config.controller && !config.template)) {
    logger.error('declareState called with wrong params. Missing template or controller', $stateProvider, config)
  }

  if (config.componentName && typeof config.componentName !== 'string') {
    logger.error('declareState called with wrong params. componentName must be string', $stateProvider, config)
  }

  if (!config.controller && !config.componentName && !config.redirectTo) {
    logger.error('declareState called with wrong params. Missing componentName or controller or redirectTo', $stateProvider, config)
  }

  $stateProvider.state(state, {
    url: `/${config.stateName}${paramString}`,
    views: {
      ['content@' + baseState]: contentConfig
    },
    redirectTo: config.redirectTo,
    data,
    abstract: !!config.abstract
  })
}


export default declareState
