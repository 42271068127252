import EntityUtils from 'common/utils/entityUtils'
import PhoneNumber from './PhoneNumber'
import Address from './Address'

export const PARTY_TYPES = {
  BRANCH: 'branch',
  CONSUMER: 'consumer',
  FREE_RIDE: 'freeRide'
}

export default class PartyDescriptor {
  constructor(
    public id: string = '',
    public type: string = '',
    public address: Address,
    public area: string,
    public consumerFirstName: string,
    public consumerLastName: string,
    public phoneNumber: PhoneNumber,
    public name: string,
    public businessId: string,
    public branchId: string,
    public branchName: string = '',
    public businessName: string = ''
  ) {}

  static build (dto: any): PartyDescriptor {
    const addressInstance = EntityUtils.castInstanceFromItselfOrId(dto.address, dto.addressId)
    const consumerFirstName = dto.consumerFirstName || ''
    const consumerLastName = dto.consumerLastName || ''
    const phoneNumberDTO = dto.phoneNumber && typeof dto.phoneNumber === 'object' ? dto.phoneNumber : dto
    const name = dto.name || ''
    const businessId = dto.businessId || null
    const branchId = dto.branchId || null

    return new PartyDescriptor(
      dto.id || '',
      dto.type,
      Address.build(addressInstance, {}, consumerFirstName, consumerLastName),
      dto.area || '',
      consumerFirstName,
      consumerLastName,
      PhoneNumber.build(phoneNumberDTO),
      name,
      businessId,
      branchId,
      dto.branchName,
      dto.businessName
    )
  }

  isBranch (): boolean {
    return this.type === PARTY_TYPES.BRANCH
  }

  isConsumer (): boolean {
    return this.type === PARTY_TYPES.CONSUMER
  }

  isFreeRide (): boolean {
    return this.type === PARTY_TYPES.FREE_RIDE
  }

  get fullName (): string {
    return `${this.consumerFirstName} ${this.consumerLastName}`
  }
}
