import { EVENT_TYPES, DELIVERY_TYPES } from 'data/collections/deliveriesCollection'

export default class DeliveryEvent {
  public isFreeRide: boolean = false

  constructor (
    public atUtc: string = '',
    public type: string = '',
    public payload: any = {},
    public initiatedByUserName: string = '',
    public driverData: string = '') {

      // fix ride creation event
      if (this.type === EVENT_TYPES.DELIVERY.CREATE && this.payload && this.payload.type === DELIVERY_TYPES.RIDE) {
        this.type = EVENT_TYPES.RIDE.CREATE
        if (!this.payload.recipient.address) {
          this.isFreeRide = true
        }
      }
  }

  static build (dto: any = {}): DeliveryEvent {
    return new DeliveryEvent(
      dto.atUtc,
      dto.type,
      dto.payload
    )
  }
}
