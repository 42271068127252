import MultiLangString from './MultiLangString'

class BranchContactDetails {
  constructor(
    public fullName: string = '',
    public email: string = '',
    public phoneNumber: string = '',
    public countryDiallingCode: string = ''
  ) {}

  static build(branchContactDetailsDTO: any): BranchContactDetails {
    return new BranchContactDetails(
      branchContactDetailsDTO.fullName,
      branchContactDetailsDTO.email,
      branchContactDetailsDTO.phoneNumber,
      branchContactDetailsDTO.countryDiallingCode
    )
  }
}

class BranchContacts {
  constructor(
    public en: BranchContactDetails = new BranchContactDetails(),
    public ar: BranchContactDetails = new BranchContactDetails()
  ) {}

  static build(BranchContactsDTO: any): BranchContacts {
    return new BranchContacts(
      BranchContactsDTO.en,
      BranchContactsDTO.ar
    )
  }
}

export default class BranchEntity {
  constructor(
    public name: MultiLangString = new MultiLangString(),
    public contacts: BranchContacts = new BranchContacts(),
    public addressId: string = '',
    public businessId: string = '',
    public cashBalanceAccountId: string = '',
    public id: string = ''
  ) {}

  set defaultCountryCode(code: string) {
    this.contacts.en.countryDiallingCode = code
    this.contacts.ar.countryDiallingCode = code
  }

  static build(branchDTO: any): BranchEntity {
    return new BranchEntity(
      MultiLangString.build(branchDTO.name),
      BranchContacts.build(branchDTO.contacts),
      branchDTO.addressId,
      branchDTO.businessId,
      branchDTO.cashBalanceAccountId,
      branchDTO.id
    )
  }
}
