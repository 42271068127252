export default class User {
  constructor (
    public id: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public fullName: string = '',
    public email: string = '',
    public countryDiallingCode: string = '',
    public phoneNumber: string = '',
    public overrideLocale: string = ''
  ) {}

  static build(userDTO: any = {}): User {
    return new User(
      userDTO.id,
      userDTO.firstName,
      userDTO.lastName,
      userDTO.fullName,
      userDTO.email,
      userDTO.countryDiallingCode,
      userDTO.phoneNumber,
      userDTO.overrideLocale
    )
  }

  toJSON(): any {
    return {
      ...this,
      countryDiallingCode: this.phoneNumber ? this.countryDiallingCode : undefined
    }
  }
}
