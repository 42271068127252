import {init, captureException} from '@sentry/browser'

import {module} from 'angular'
import envConfig from 'common/envConfig'

const config = {
  dsn: envConfig.apiKeys.sentryUrl,
  release: envConfig.version,
  enabled: !!envConfig.version
}

init(config)

module('sentry', []).factory('$exceptionHandler', ['$log', ($log: ng.ILogService): any => {
  return (exception: any, cause: any): void => {
    $log.error(exception, cause)
    captureException(exception)
  }
}])
