import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Driver from 'data/domain-objects/Driver'

const driverService = new CrudService(endpoints.DRIVER)
const driversService = new CrudService(endpoints.DRIVERS)
const driverVerificationCodeSendService = new CrudService(endpoints.DRIVER_VERIFICATION_CODE_SEND)
const driverVerificationCodeService = new CrudService(endpoints.DRIVER_VERIFICATION_CODE)
const driverVerified = new CrudService(endpoints.DRIVER_VERIFIED)
const driverCashService = new CrudService(endpoints.DRIVER_CASH)
const useCacheParams = {useCache: true}

export function driverCollectionFactory (driverService, driversService, driverVerificationCodeSendService, driverVerificationCodeService, driverVerified, driverCashService) {
  return {
    getCached: function (driverId) {
      return driverService.get([driverId], useCacheParams)
        .then(driverDTO => Driver.build(driverDTO))
    },
    get: function (driverId) {
      return driverService.get([driverId])
        .then(driverDTO => Driver.build(driverDTO))
    },
    getWithCashBalance: function (driverId) {
      return driverService.get([driverId], {
        populate: 'cashBalanceAccountId'
      })
    },
    getAll: function (fleetId, searchQuery) {
      const params = {}

      if (fleetId) {
        params.fleetId = fleetId
      }

      if (searchQuery) {
        params.search = searchQuery
      }

      return driversService.get([], params)
        .then(driverDTOs => driverDTOs.map(driverDTO => Driver.build(driverDTO)))
    },
    getCash: function (driverId) {
      return driverCashService.get([driverId])
    },
    setCash: function (driverBalanceObject, driverId) {
      return driverCashService.create(driverBalanceObject, [driverId])
    },
    sendVerification: function (driverId) {
      return driverVerificationCodeSendService.create({}, [driverId])
    },
    verifyAccount: function (driverId, code) {
      return driverVerificationCodeService.update({code}, [driverId])
    },
    checkIsVerified: function (driverId) {
      return driverVerified.get([driverId])
    },
    'delete': function (driverId) {
      return driverService.delete([driverId])
    }
  }
}

export default driverCollectionFactory(driverService, driversService, driverVerificationCodeSendService, driverVerificationCodeService, driverVerified, driverCashService)
