import deliveriesCollection from 'data/collections/deliveriesCollection'
import addressVerificationCollection from 'data/collections/addressVerificationCollection'
import Logger from 'common/Logger'
import localeHelper from 'common/localeHelper'
export const SETTINGS_KEY_TEMPLATE_NAME = 'verifyAddressTemplate'
export const SETTINGS_KEY_CONFIGURATION = 'verifyAddressConfiguration'
const TEMPLATES_URL_PREFIX = '/address-verification-templates/'
const logger = new Logger('Address Verification Service')
const TEMPLATES_LIST = ['draewil', 'seoul-kool', 'finger-sushi', 'dabdoob', 'mais']
const DEFAULT_TEMPLATE = 'draewil'

export default {
  getTemplatesList: function () {
    return TEMPLATES_LIST
  },
  getDefaultTemplate: function () {
    return DEFAULT_TEMPLATE
  },
  changeLocale: function (deliveryId, locale) {
    return addressVerificationCollection.saveLocale(deliveryId, locale)
      .then(() => localeHelper.onLocaleChange(locale))
  },

  verifyAddress: function (deliveryId, address) {
    return addressVerificationCollection.verifyAddress(deliveryId, address)
  },

  getDelivery: function (deliveryId) {
    return deliveriesCollection.trackDelivery(deliveryId)
  },

  confirmAddress: function (deliveryId, address) {
    const addressLocation = {
      location: {
        latitude: parseFloat(address.location.latitude),
        longitude: parseFloat(address.location.longitude)
      },
      addressId: address.id
    }
    return addressVerificationCollection.verifyAddress(deliveryId, addressLocation)
  },

  getConfiguration: function (deliveryId) {
    return addressVerificationCollection.get(deliveryId)
      .then(settings => {
        let styleConfig
        try {
          styleConfig = JSON.parse(settings.verifyAddressConfiguration)
        } catch (error) {
          logger.error('Tried to JSON.parse VAF configuration settings for delivery ', deliveryId, ' but got error ', error)
        }

        if (styleConfig === null) {
          styleConfig = JSON.parse(this.getDefaultConfiguration())
        }

        const templateUrl = `${TEMPLATES_URL_PREFIX}${ settings.verifyAddressTemplate || this.getDefaultTemplate() }.html`

        const locale = settings.locale || localeHelper.getLocale()

        return {
          styleConfig,
          templateUrl,
          locale
        }
      })
  },

  getDefaultConfiguration: function () {
    const config = {
      header: {
        backgroundColor: 'inherit',
        fontColor: '#fff',
        fontSize: '18px',
        isHidden: false
      },
      footer: {
        backgroundColor: 'inherit',
        height: '150px'
      },
      instructions: {
        fontColor: '#808080',
        isHidden: true,
        backgroundColor: '#eeeeff'
      },
      callButton: {
        backgroundColor: '#71b6ef',
        fontColor: '#ffffff',
        isHidden: false
      },
      backgroundColor: '#192937'
    }
    return JSON.stringify(config, null, '    ')
  }
}
