import localeHelper from 'common/localeHelper'

export default class MultiLangString {
  constructor(
    public en: string = '',
    public ar: string = ''
  ) {}

  public get length (): number {
    return this.ar.length + this.en.length
  }

  static build (dto: any = {}): MultiLangString {
    return new MultiLangString(
      dto.en || '',
      dto.ar || ''
    )
  }

  public getLocalized (lang: string = localeHelper.getLanguage()): string {
    return this[lang] || ''
  }
}
