class TitledPageController {
  // inputs
  public titleKey: string
  public titleText: string
  public titleExplanation: string
  public hostClass: string

  // props
  public isContentSlotProvided: boolean

  constructor($transclude: ng.ITranscludeFunction) {
    this.isContentSlotProvided = $transclude.isSlotFilled('contentSlot')
  }
}

export default {
  templateUrl: require('./titled-page.pug'),
  controller: TitledPageController,
  transclude: {
    titleSlot: '?titledPageTitle',
    additionalsSlot: '?titledPageAdditionals',
    contentSlot: '?titledPageContent'
  },
  bindings: {
    titleKey: '@',
    titleText: '@',
    titleExplanation: '@',
    hostClass: '@?'
  }
}
