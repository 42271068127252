export default class Setting {
  constructor (
    public defaultValue: any = '',
    public isOverridden: boolean = false,
    public value: any = ''
  ) {}

  static build (dto: any = {}): Setting {
    return new Setting(
      dto.defaultValue,
      dto.isOverridden,
      dto.value
    )
  }
}
