import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Consumer from 'data/domain-objects/Consumer'
import Address from 'data/domain-objects/Address'
import addressCollection from './addressCollection'
import PhoneNumber from 'data/domain-objects/PhoneNumber'
import { ConsumerHistoryBalance } from 'data/domain-objects/ConsumerBalanceHistory'

const consumerSearchOrCreateService = new CrudService(endpoints.CONSUMERS_SEARCH_OR_CREATE)
const consumerService = new CrudService(endpoints.CONSUMER)
const consumersService = new CrudService(endpoints.CONSUMERS)
const consumerSearchService = new CrudService(endpoints.CONSUMERS_SEARCH)
const consumerBalanceHistoryService = new CrudService(endpoints.CONSUMER_BALANCE_HISTORY)

export default {

  getBalanceHistory: (consumerId: string, pagination: IPaginationMetadata): Promise<ConsumerHistoryBalance> => {
    return consumerBalanceHistoryService.get([consumerId], {
      skip: pagination.skip,
      limit: pagination.limit
    }).then(ConsumerHistoryBalance.build)
  },

  get: (consumerId: string, extendWithAddresses: boolean = false): Promise<Consumer> => {
    return consumerService.get([consumerId])
      .then((consumerDO: Consumer) => {
        if (!extendWithAddresses) {
          return Consumer.build(consumerDO)
        }

        return addressCollection.getConsumerAddresses(consumerDO.id)
          .then((addresses: Address[]) => Consumer.build(Object.assign(consumerDO, {addresses})))
      })
  },

  getAll: (): Promise<Consumer[]> => {
    return consumersService.get()
  },

  getConsumerByPhoneNumberDO: (phoneNumberDO: PhoneNumber): Promise<Consumer[]> => {
    return consumerSearchService.get([], phoneNumberDO)
  },

  create: (consumerDO: Consumer): Promise<Consumer> => {
    return consumerService.create(consumerDO, [], {})
  },

  update: (consumerDO: Consumer): Promise<Consumer> => {
    return consumerService.update(consumerDO, [consumerDO.id], {})
  },

  searchOrCreateByPhoneNumberDO:  (phoneNumberDO: PhoneNumber): Promise<Consumer[]> => {
    return consumerSearchOrCreateService.create(phoneNumberDO, [], {})
      .then((consumer: Consumer) => [consumer])
  },
}
