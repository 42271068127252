import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Setting from 'data/domain-objects/Setting'
import {BusinessRegistrationData} from 'data/domain-objects/BusinessRegistrationData'

const businessService = new CrudService(endpoints.BUSINESS)
const businessAllLanguagesService = new CrudService(endpoints.BUSINESS_ALL_LANGUAGES)
const businessesService = new CrudService(endpoints.BUSINESSES)
const businessSettingsService = new CrudService(endpoints.BUSINESSES_SETTINGS)
const businessStorefrontSettingsService = new CrudService(endpoints.BUSINESS_STOREFRONT_SETTINGS)
const businessStorefrontSettingsServiceAllLanguages = new CrudService(endpoints.BUSINESS_STOREFRONT_SETTINGS_ALL_LANGUAGES)
const businessStorefrontDetailsService = new CrudService(endpoints.STOREFRONT_BUSINESS_DETAILS)
const businessRegistrationDataServiceAllLanguages = new CrudService(endpoints.BUSINESS_REGISTRATION_DATA_ALL_LANGUAGES)
const businessRegistrationDataService = new CrudService(endpoints.BUSINESS_REGISTRATION_DATA)
const useCacheParams = {useCache: true}

const HTTP_NOT_FOUND_STATUS_CODE = 404

export default {
  get: businessId => {
    return businessService.get([businessId])
  },
  getStorefrontDetails: businessId => {
    return businessStorefrontDetailsService.get([businessId])
  },
  getWithBranches: businessId => {
    return businessService.get([businessId], {populate: 'branches'})
  },
  getWithAllLanguages: businessId => {
    return businessAllLanguagesService.get([businessId])
  },
  getCached: businessId => {
    return businessService.get([businessId], useCacheParams)
  },
  getAll: () => { // Under the hood this method returns businesses restricted to account but not the list of ALL businesses. Maybe we should rename this method?
    return businessesService.get([])
  },
  getBusinessesWithTheirBranches: () => {
    return businessesService.get([], {populate: 'branches'})
  },
  getBusinessSettings: (businessId, useCache) => {
    return businessSettingsService.get([businessId], useCache ? useCacheParams : {})
      .then(settings => {
        for (const settingKey in settings) {
          if (settings.hasOwnProperty(settingKey)) {
            settings[settingKey] = Setting.build(settings[settingKey])
          }
        }
        return settings
      })
  },
  updateBusinessSettings: (businessId, settings) => {
    return businessSettingsService.update(settings, [businessId])
  },
  'delete': businessId => {
    return businessService.delete([businessId])
  },
  create: business => {
    return businessService.create(business)
  },
  update: (business, businessId) => {
    return businessService.update(business, [businessId])
  },
  getStorefrontSettings: businessId => {
    return businessStorefrontSettingsServiceAllLanguages.get([businessId])
  },
  updateStorefrontSettings: (settings, businessid) => {
    return businessStorefrontSettingsService.update(settings, [businessid])
  },
  getRegistrationData: businessId => {
    return businessRegistrationDataServiceAllLanguages.get([businessId])
      .then(response => {
        if (!(Array.isArray(response.contacts) && response.contacts.length)) {
          throw new Error('Invalid backend response', response)
        }

        return BusinessRegistrationData.build(response)
      })
      .catch(error => {
        if (error.status === HTTP_NOT_FOUND_STATUS_CODE) {
          return BusinessRegistrationData.build({
            contacts: [{}]
          })
        }
        throw error
      })
  },
  updateRegistrationData: (businessId, registrationData) => {
    return businessRegistrationDataService.update(
      {
        ...registrationData,
        id: undefined,
        contacts: [{
          firstName: registrationData.contacts[0].firstName,
          lastName: registrationData.contacts[0].lastName,
          countryDiallingCode: registrationData.contacts[0].countryDiallingCode,
          phoneNumber: registrationData.contacts[0].phoneNumber,
          email: registrationData.contacts[0].email
        }]
      },
      [businessId]
    )
  }
}
