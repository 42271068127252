/* globals google:false */
import envConfig from 'common/envConfig'
import ParseUtils from 'common/utils/parseUtils'
import {MathUtils} from 'common/utils/mathUtils'

const EARTH_RADIUS_IN_KM = 6371

// https://stackoverflow.com/questions/365826/calculate-distance-between-2-gps-coordinates
export const getDistanceInKmBetweenCoordinates = function (latitude1, longitude1, latitude2, longitude2) {
  const dLat = MathUtils.degreesToRadians(latitude2 - latitude1)
  const dLon = MathUtils.degreesToRadians(longitude2 - longitude1)
  const rLat1 = MathUtils.degreesToRadians(latitude1)
  const rLat2 = MathUtils.degreesToRadians(latitude2)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return EARTH_RADIUS_IN_KM * c
}

export const checkStatusHasChanged = function (currentStatus, renderedMarker) {
  return renderedMarker.marker && renderedMarker.marker.status !== currentStatus
}

export const checkHasLatLngChanged = function (currentPoint, previousPoint) {
  return currentPoint && currentPoint.lat && currentPoint.lng &&
      previousPoint && previousPoint.lat && previousPoint.lng &&
      (currentPoint.lat() !== previousPoint.lat() || currentPoint.lng() !== previousPoint.lng())
}

export class GoogleMapsPoint {
  constructor (latitude, longitude) {
    this.lat = ParseUtils.safeParseNumber(latitude, 0)
    this.lng = ParseUtils.safeParseNumber(longitude, 0)
  }
}

export class GoogleMapsPadding {
  constructor (top = 0, right = 0, bottom = 0, left = 0) {
    this.top = top
    this.right = right
    this.bottom = bottom
    this.left = left
  }
}

export class GoogleMapsOptions {
  constructor (requiredCenterPoint, zoom, styles) {
    return {
      center: requiredCenterPoint,
      gestureHandling: 'cooperative',
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      maxZoom: envConfig.googleMapsSettings.maxZoom,
      minZoom: envConfig.googleMapsSettings.minZoom,
      zoom,
      styles
    }
  }
}

export const generateMapOptions = function (lat, lng, styles, zoom) {
  return {
    center: {lat, lng},
    styles,
    zoom,
    gestureHandling: 'greedy',
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    draggable: true,
    disableDefaultUI: false,
    disableDoubleClickZoom: false,
    scrollwheel: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  }
}

export const createSVGMarker = (map, lat, lng, svg, markerSize, zIndex = 1) => {
  // stolen from https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
  const encodedSVG = window.btoa(unescape(encodeURIComponent(svg)))

  return new google.maps.Marker({
    position: new google.maps.LatLng(lat, lng),
    map,
    draggable: false,
    icon: {
      scaledSize: new google.maps.Size(markerSize, markerSize),
      url: `data:image/svg+xml;charset=UTF-8;base64,${encodedSVG}`
    },
    zIndex
  })
}

export class Marker extends google.maps.OverlayView {
  constructor (googleMapsLatLng, status, onClick, markerType) {
    super()

    this.googleMapsLatLng = googleMapsLatLng
    this.element = null
    this.status = status
    this.onClick = onClick
    this.markerType = markerType
    this.mapMarkerClass = envConfig.googleMapsSettings.markerTypes[this.markerType].class
    this.mapMarkerSize = envConfig.googleMapsSettings.markerTypes[this.markerType].size
  }

  // this method is implemented only for markerclusterer library
  getPosition () {
    return this.googleMapsLatLng
  }

  onAdd () {
    this.element = document.createElement('span')
    this.element.className = `${ this.mapMarkerClass } ${ this.mapMarkerClass }--${ this.status }`

    if (this.onClick) {
      this.element.onclick = () => this.onClick()
    }

    const panes = this.getPanes()
    panes.overlayImage.appendChild(this.element)
  }

  onRemove () {
    // NB. Calling this.getPanes() here always returns null even though the panes exist. Remove without referencing panes.
    if (this.element) {
      this.element.onclick = null
      if (this.element.parentNode) {
        this.element.parentNode.removeChild(this.element)
      }
    }
  }

  draw () {
    const point = this.getProjection().fromLatLngToDivPixel(this.googleMapsLatLng)

    switch (this.markerType) {
    case envConfig.googleMapsSettings.markerTypes.pin.name: {
      const rotationRadiusLength = Math.sqrt(2 * Math.pow(this.mapMarkerSize, 2)) / 2
      const xAxisOffset = this.mapMarkerSize / 2
      const yAxisOffset = rotationRadiusLength + this.mapMarkerSize / 2
      this.element.style.left = (point.x - xAxisOffset) + 'px'
      this.element.style.top = (point.y - yAxisOffset) + 'px'
      break
    }
    default: {
      this.element.style.left = (point.x - this.mapMarkerSize / 2) + 'px'
      this.element.style.top = (point.y - this.mapMarkerSize / 2) + 'px'
      break
    }
    }
  }

  addToMap (map) {
    this.setMap(map)
  }

  removeFromMap () {
    this.setMap(null)
  }
}
