export default class DeprecatedLastKnownLocation {
  constructor (
    public atUtc: string = '',
    public latitude: number = null,
    public longitude: number = null
  ) {}

  static build (dto: any = {}): DeprecatedLastKnownLocation {
    return new DeprecatedLastKnownLocation(
      dto.atUtc,
      dto.latitude,
      dto.longitude
    )
  }
}
