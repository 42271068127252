export const enum DeliveryStatusesType {
  notReady = 'notReady',
  pending = 'pending',
  allocating = 'allocating',
  allocated = 'allocated',
  atPickup = 'atPickup',
  collected = 'collected',
  atDropoff = 'atDropoff',
  delivered = 'delivered',
  issue = 'issue',
  cancelled = 'cancelled',
  inProgress = 'inProgress'
}

export const DELIVERY_STATUSES = {
  NOT_READY: DeliveryStatusesType.notReady,
  PENDING: DeliveryStatusesType.pending,
  ALLOCATING: DeliveryStatusesType.allocating,
  ALLOCATED: DeliveryStatusesType.allocated,
  AT_PICKUP: DeliveryStatusesType.atPickup,
  COLLECTED: DeliveryStatusesType.collected,
  AT_DROPOFF: DeliveryStatusesType.atDropoff,
  DELIVERED: DeliveryStatusesType.delivered,
  ISSUE: DeliveryStatusesType.issue,
  CANCELLED: DeliveryStatusesType.cancelled,
  IN_PROGRESS: DeliveryStatusesType.inProgress
}
