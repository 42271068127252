import moment from 'moment'
import Cost from './Cost'
import Delivery from './Delivery'
import {DELIVERY_TYPES} from 'data/collections/deliveriesCollection'
import NextDayDeliveryTimeSlot from './NextDayDeliveryTimeSlot'
import {DELIVERY_STATUSES, DeliveryStatusesType} from 'common/constants/DeliveryStatuses'
import Driver from './Driver'
import Fleet from './Fleet'

export default class NextDayDelivery extends Delivery {

  public pickupSlot: NextDayDeliveryTimeSlot
  public dropoffSlot: NextDayDeliveryTimeSlot

  constructor (
    id: string = '',
    createdAt: Date,
    pickupTime: string,
    ref: string = '',
    status: DeliveryStatusesType = DELIVERY_STATUSES.NOT_READY,
    sender: any = {},
    recipient: any = {},
    public payment: any = {},
    itemsValue: any = {},
    shippingCost: Cost = Cost.build(),
    public inboundDelivery: Delivery = null,
    public outboundDelivery: Delivery = null
  ) {
    super(
      id,
      ref,
      DELIVERY_TYPES.NEXT_DAY,
      status,
      null, // statusUpdatedAt
      createdAt,
      null, // updatedAt
      pickupTime,
      null, // etaPickup
      null, // etaPickupInitial
      null, // etaDropoff
      null, // etaDropoffInitial
      Driver.build({}), // driver
      Fleet.build({}), // fleet
      sender,
      recipient,
      null, // originatingConsumer
      payment,
      null, // canBatch
      null, // balanceAccountId
      null, // events
      null, // packages
      null, // permissions
      null, // proofOfDelivery
      null, // quote
      false, // isInbound
      false, // isOutbound
    )
    this.payment.cost = Cost.build(itemsValue)
    this.payment.shippingCost = Cost.build(shippingCost)

    this.inboundDelivery = Delivery.build({
      id: inboundDelivery.id,
      ref: inboundDelivery.ref,
      type: DELIVERY_TYPES.DELIVERY,
      status: inboundDelivery.status
    })
    this.outboundDelivery = Delivery.build({
      id: outboundDelivery.id,
      ref: outboundDelivery.ref,
      type: DELIVERY_TYPES.DELIVERY,
      status: outboundDelivery.status
    })
    const dropoffTime = moment(pickupTime).add(1, 'days').toDate()
    this.pickupSlot = NextDayDeliveryTimeSlot.buildFromDate(new Date(pickupTime))
    this.dropoffSlot = NextDayDeliveryTimeSlot.buildFromDate(dropoffTime)
  }

  static build (dto: any = {}): NextDayDelivery {
    const payment = {
      posReference: dto.pos,
      method: dto.paymentMethod
    }

    return new NextDayDelivery(
      dto.id,
      dto.createdAt,
      dto.pickupTime,
      dto.ref,
      dto.status,
      dto.sender,
      dto.recipient,
      payment,
      dto.itemsValue,
      dto.cost,
      dto.inboundDelivery,
      dto.outboundDelivery
    )
  }
}
