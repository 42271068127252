export default class EntityUtils {
  static castInstanceFromItselfOrId (instanceObject: any, instanceId: string): any {

    if (instanceObject && typeof instanceObject !== 'string' && instanceObject.id) {
      return instanceObject
    } else if (instanceObject && typeof instanceObject === 'string') {
      return {
        id: instanceObject // actually it seems that this is ID!
      }
    } else if (instanceId) {
      return {
        id: instanceId
      }
    }

    return {
      id: ''
    }
  }
}
