/* eslint-disable no-console */
// This should be the only file which references the browser console directly. Everything else should use this logger.

import {captureException} from '@sentry/browser'
import envConfig from './envConfig'

class Logger {
  static get LOG_LEVEL () { // Static log levels enum, using level names from Log4j and targeting native error/warn/info as supported in IE
    return {
      OFF: 0,
      ERROR: 1,
      WARN: 2,
      INFO: 3
    }
  }

  constructor (consumerName) {
    if (typeof consumerName !== 'string') {
      throw new Error('Logger must be initialised with a consumer name string')
    }
    this.consumerName = consumerName
    this.logLevel = (envConfig.logLevel ? Logger.LOG_LEVEL[envConfig.logLevel] : Logger.LOG_LEVEL.INFO) // Default to info
  }

  info (...args) {
    if (this.logLevel >= Logger.LOG_LEVEL.INFO) {
      const logArgs = [this.consumerName + ':', ...args]
      console.info.apply(console, logArgs)
    }
  }

  warn (...args) {
    if (this.logLevel >= Logger.LOG_LEVEL.WARN) {
      const logArgs = [this.consumerName + ':', ...args]
      console.warn.apply(console, logArgs)
    }
  }

  error (...args) {
    if (this.logLevel >= Logger.LOG_LEVEL.ERROR) {
      const logArgs = [this.consumerName + ':', ...args]
      let doCaptureExceptionForSentry = true
      for (const argument of logArgs) {
        if (typeof argument === 'object' && argument.isBackendError) { // searching for error object and check isBackendError flag which is set in agent.js
          doCaptureExceptionForSentry = false // BE errors are BE errors, we don't take them into account
        }
      }
      console.error.apply(console, logArgs)
      if (doCaptureExceptionForSentry) {
        captureException(...args)
      }
    }
  }
}

export default Logger
