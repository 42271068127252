import StorefrontAreaSettings from './StorefrontAreaSettings'

export default class StorefrontBranchAreasConfiguration {
  constructor (
    public areas: string[],
    public settings: StorefrontAreaSettings
  ) {}

  public static build (dto: any): StorefrontBranchAreasConfiguration {
    return new StorefrontBranchAreasConfiguration(
      dto.areas || [],
      StorefrontAreaSettings.build(dto.settings || {})
    )
  }
}
