import find from 'lodash-es/find'
import Address from 'data/domain-objects/Address'
import envConfig from 'common/envConfig'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import moment from 'moment'
import addressCollection from './addressCollection'
import businessesCollection from './businessesCollection'
import branchesCollection from './branchesCollection'
import driverCollection from './driverCollection'
import fleetsCollection from './fleetsCollection'
import consumerCollection from './consumerCollection'
import Driver from 'data/domain-objects/Driver'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import DeliveryEvent from 'data/domain-objects/DeliveryEvent'
import NextDayDelivery from 'data/domain-objects/NextDayDelivery'
import Delivery from 'data/domain-objects/Delivery'

export const DELIVERY_ACTIONS = {
  VIEW_IN_NEW_WINDOW: 'delivery-view-in-new-window',
  VIEW: 'delivery-view',
  MARK_READY: 'delivery-mark-ready',
  MARK_PENDING: 'delivery-mark-pending',
  MARK_COLLECTED: 'delivery-mark-collected',
  SYNC: 'delivery-sync',
  REHAIL: 'delivey-rehail',
  MARK_DELIVERED: 'delivery-mark-delivered',
  CHANGE_DRIVER: 'delivery-change-driver',
  CANCEL: 'delivery-cancel',
  STATUS_UPDATE_FINISHED: 'delivery-status-update-finished',
  BATCHING: 'delivery-batching',
  TRANSFER_DELIVERIES: 'transfer-deliveries',
  LOADING: 'delivery-loading'
}

export const PARTY_TYPES = {
  BRANCH: 'branch',
  CONSUMER: 'consumer'
}

export const DELIVERY_TYPES = {
  DELIVERY: 'delivery',
  RIDE: 'ride',
  NEXT_DAY: 'nextDay'
}
export const EVENT_TYPES = {
  ADDRESS: {
    UPDATE: 'address.update'
  },
  RIDE: {
    CREATE: 'ride.create'
  },
  DELIVERY: {
    CREATE: 'delivery.create',
    UPDATE: 'delivery.update',
    COLLECT: 'delivery.collect',
    PAYMENT: 'delivery.payment',
    CHARGE: 'delivery.charge',
    DELIVER: 'delivery.deliver',
    ASSIGN: 'delivery.assign',
    CANCEL: 'delivery.cancel',
    ISSUE: 'delivery.issue',
    AT_ADDRESS: 'delivery.atAddress',
    SET_STATUS: 'delivery.setStatus',
    OVERRIDE_STATUS: 'delivery.overrideStatus',
    LOCATION: {
      UPDATE: 'delivery.location.update'
    },
    ETA: {
      UPDATE: 'delivery.eta.update'
    }
  }
}
const DEFAULT_RECENT_DELIVERIES_PERIOD_HOURS = 24
const MS_PER_HOUR = 3600000
const THIRTY_SECONDS_TO_MARS = 30000 // just 30 seconds in ms
const RECENT_DELIVERIES_TIME_PERIOD_MS = (envConfig.recentDeliveriesPeriodHours || DEFAULT_RECENT_DELIVERIES_PERIOD_HOURS) * MS_PER_HOUR
const deliveryService = new CrudService(endpoints.DELIVERY)
const deliveryGetService = new CrudService(endpoints.DELIVERY_GET)
const deliveryNextDayService = new CrudService(endpoints.DELIVERY_NEXT_DAY)
const deliveryNextDayListService = new CrudService(endpoints.DELIVERY_NEXT_DAY_LIST)
const deliveryNextDayLinkedListService = new CrudService(endpoints.DELIVERY_NEXT_DAY_UNALLOCATED_LINKED)
const deliveryStatusService = new CrudService(endpoints.DELIVERY_STATUS)
const deliveryOverrideStatusService = new CrudService(endpoints.DELIVERY_OVERRIDE_STATUS)
const kanbanService = new CrudService(endpoints.KANBAN)
const deliveriesAvailableForBatchingService = new CrudService(endpoints.DELIVERIES_AVAILABLE_FOR_BATCHING)
const deliveryReceiptTemplateService = new CrudService(endpoints.DELIVERY_RECEIPT_TEMPLATE)
const nextDayDeliveryTransferAvailableDriversService = new CrudService(endpoints.DELIVERY_NEXT_DAY_AVAILABLE_DRIVERS)
const deliveryTransferAvailableDriversService = new CrudService(endpoints.DELIVERY_TRANSFER_AVAILABLE_DRIVERS)
const deliveryTransferToDriverService = new CrudService(endpoints.DELIVERY_TRANSFER_TO_DRIVER)
const deliveriesTransferToDriverService = new CrudService(endpoints.DELIVERIES_TRANSFER_TO_DRIVER)
const deliveryBatchingService = new CrudService(endpoints.DELIVERY_BATCHING)
const deliveryTrackingService = new CrudService(endpoints.DELIVERY_TRACKING)
const scheduledDeliveriesService = new CrudService(endpoints.SCHEDULED_DELIVERIES)

export function deliveriesCollectionFactory () {
  function getById (deliveryId) {
    const NO_CACHE = true
    return deliveryGetService.get([deliveryId], {}, NO_CACHE)
      .then(delivery => {
        if (delivery.events) {
          delivery.events = delivery.events.map(DeliveryEvent.build)
        }
        return delivery
      })
      .then(delivery => extendDelivery(delivery))
  }

  function trackDelivery (deliveryId) {
    return deliveryTrackingService.get([deliveryId], {}, true) // we pass true here to prevent duplicate calls cache
      .then(delivery => {
        delivery.recipient.address = Address.build(delivery.recipient.address)
        delivery.sender.address = Address.build(delivery.sender.address)
        return delivery
      })
  }

  function extendDelivery (delivery) {
    const pickedUpAt = getEventLogTimestampFor(delivery, EVENT_TYPES.DELIVERY.COLLECT)
    const droppedOffAt = getEventLogTimestampFor(delivery, EVENT_TYPES.DELIVERY.DELIVER)

    return Promise.all([
      extendAddressGivenAddressDescriptor(delivery.sender),
      extendAddressGivenAddressDescriptor(delivery.recipient),
      fleetsCollection.getCached(delivery.fleetId),
      (delivery.driverId) ? driverCollection.get(delivery.driverId) : null
    ]).then(([sender, recipient, fleet, driver]) => {
      return Object.assign(delivery, {
        sender,
        recipient,
        fleet,
        driver,
        pickedUpAt,
        droppedOffAt
      })
    })
  }

  function extendAddressGivenAddressDescriptor (addressDescriptor) {
    if (addressDescriptor.type === PARTY_TYPES.BRANCH) {
      return extendBranchAddress(addressDescriptor)
    } else {
      return extendConsumerAddress(addressDescriptor)
    }
  }

  function extendConsumerAddress (addressDescriptor) {
    return Promise.all([
      consumerCollection.get(addressDescriptor.id),
      addressCollection.buildAddressGivenAddressDescriptor(addressDescriptor.address)
    ])
      .then(([consumer, consumerAddress]) => {
        // free rides don't need locaton
        if (!consumerAddress.id) {
          delete consumerAddress.location
        }
        return Object.assign({}, addressDescriptor, {
          address: consumerAddress,
          name: `${addressDescriptor.consumerFirstName || '—'} ${addressDescriptor.consumerLastName || '—'}`,
          contactCountryDiallingCode: consumer.countryDiallingCode,
          contactPhoneNumber: consumer.phoneNumber
        })
      })
  }

  function extendBranchAddress (branchAddressDescriptor) {
    return branchesCollection.getCached(branchAddressDescriptor.id)
      .then(branch => {
        return Promise.all([
          branch,
          businessesCollection.getCached(branch.businessId),
          addressCollection.buildAddressGivenAddressDescriptor(branchAddressDescriptor.address)
        ])
      })
      .then(([branch, business, address]) => {
        return Object.assign({}, branchAddressDescriptor, {
          address,
          businessId: business.id,
          name: `${business.name} - ${branch.name}`,
          contactCountryDiallingCode: business.contactCountryDiallingCode,
          contactPhoneNumber: business.contactPhoneNumber,
          logoUrl: business.logoUrl
        })
      })
  }

  function getEventLogTimestampFor (delivery, eventType) {
    const events = delivery.events || []
    const eventWithKey = find(events, {type: eventType}) || {}
    return eventWithKey.atUtc || null
  }

  function deleteDeliveryEvents (delivery) {
    const deliveryWithoutEvents = {...delivery}
    delete deliveryWithoutEvents.events
    return deliveryWithoutEvents
  }

  function updateDeliveryRecipient (id, recipient) {
    return deliveryService.get([id])
      .then(delivery => {
        delivery.recipient = recipient
        return deliveryService.update(deleteDeliveryEvents(delivery), [id])
      })
  }

  function getTimestampFromNow (periodMs) {
    const nowTimestamp = Date.now()
    const offsetTimestamp = nowTimestamp - periodMs
    return new Date(offsetTimestamp).toISOString()
  }

  function getAvailableDriversToTransfer (deliveryId) {
    return deliveryTransferAvailableDriversService.get([deliveryId])
      .then(drivers => drivers.map(driverDTO => Driver.build(driverDTO)))
  }

  function transferDeliveryToDriver (deliveryId, driverId) {
    return deliveryTransferToDriverService.update({}, [deliveryId, driverId])
  }

  function getNextDayDeliveries (limit, skip, status) {
    const queryParams = {limit, skip}

    if (status) {
      queryParams.status = status
    }

    return deliveryNextDayListService.get([], queryParams)
      .then(response => {
        response.records = response.records.map(nextDayDeliveryDTO => NextDayDelivery.build(nextDayDeliveryDTO))
        return response
      })
  }

  function getNextDayLinkedDeliveries (params) {
    return deliveryNextDayLinkedListService.get([], params)
      .then(response => {
        response.records = response.records.map(deliveryDTO => Delivery.buildFromNextDayDeliveryDTO(deliveryDTO))
        return response
      })
  }

  function transferDeliveriesToDriver (deliveryIds, driverId) {
    return deliveriesTransferToDriverService.update({
      driverId,
      deliveryIds
    })
  }

  function transferNextDayDeliveriesToDriver () {
    return nextDayDeliveryTransferAvailableDriversService.get()
  }

  function getScheduled (limit, skip, status) {
    const queryParams = {limit, skip}

    if (status) {
      queryParams.status = status
    }

    return scheduledDeliveriesService.get([], queryParams)
      .then(response => {
        response.records = response.records.map(deliveryDTO => {
          if (deliveryDTO.fleet && deliveryDTO.fleet.name) {
            deliveryDTO.fleet.id = '-' // DP-7545 hack
          }
          return Delivery.build(deliveryDTO)
        })
        return response
      })
  }

  return {
    create: function (entity) {
      return deliveryService.create(entity)
    },
    createNextDay: function (entity) {
      return deliveryNextDayService.create(entity)
    },
    deleteNextDayDelivery: function (deliveryId) {
      return deliveryNextDayService.delete([deliveryId])
    },
    getNextDayDeliveries,
    getBatchableDeliveries: function (fleetId) {
      const params = {
        fleetId: fleetId
      }
      return deliveriesAvailableForBatchingService.get([], params)
    },
    update: function (entity) {
      return deliveryService.update(deleteDeliveryEvents(entity), [entity.id])
    },
    'delete': function (id) {
      return deliveryService.delete([id])
    },
    trackDelivery,
    getById,
    updateDeliveryRecipient,
    DELIVERY_STATUSES,
    getDeliveryReceiptTemplate: function (deliveryId) {
      return deliveryReceiptTemplateService.get([deliveryId])
    },
    markAsReady: function (deliveryId) {
      return deliveryStatusService.update({status: DELIVERY_STATUSES.PENDING}, [deliveryId])
    },
    markAsPending: function (deliveryId) {
      return deliveryStatusService.update({status: DELIVERY_STATUSES.NOT_READY}, [deliveryId])
    },
    rehail: function (deliveryId) {
      return deliveryStatusService.update({status: DELIVERY_STATUSES.PENDING}, [deliveryId])
    },
    batchDelivery: function (deliveryId, autoBatching, batchWithDeliveryId) {
      const params = {
        canBatch: autoBatching,
        batchWithDeliveryId
      }

      return deliveryBatchingService.update(params, [deliveryId])
    },
    overrideAsDelivered: function (deliveryId) {
      return deliveryOverrideStatusService.update({status: DELIVERY_STATUSES.DELIVERED}, [deliveryId])
    },
    overrideAsCollected: function (deliveryId) {
      return deliveryOverrideStatusService.update({status: DELIVERY_STATUSES.COLLECTED}, [deliveryId])
    },

    getKanbanData: function (params = {}) {
      const filterPeriodTimestamp = getTimestampFromNow(RECENT_DELIVERIES_TIME_PERIOD_MS)
      const startDate = params.startDate && params.startDate.toISOString() || filterPeriodTimestamp
      const updatedAtDate = new Date(Date.now() - THIRTY_SECONDS_TO_MARS).toISOString()
      const statusPromises = []

      const statuses = (params.statuses.indexOf(DELIVERY_STATUSES.ISSUE) > -1) ? params.statuses.filter(status => (status !== DELIVERY_STATUSES.CANCELLED)) : params.statuses

      statuses.forEach(status => {
        const filterStatus = (status === DELIVERY_STATUSES.ISSUE) ? [DELIVERY_STATUSES.ISSUE, DELIVERY_STATUSES.CANCELLED] : status

        const reqParams = {
          limit: params.limit,
          status: filterStatus
        }

        if (params.initialLoad) {
          reqParams.createdAfter = startDate
        } else {
          reqParams.statusUpdatedAfter = updatedAtDate
        }

        if (params.businessId) {
          reqParams.businessId = params.businessId
        }

        if (params.branchId) {
          reqParams.branchId = params.branchId
        }

        if (params.fleetId) {
          reqParams.fleetId = params.fleetId
        }

        if (params.searchQuery) {
          reqParams.searchText = params.searchQuery
          reqParams.createdAfter = moment().subtract(envConfig.kanban.searchTextFilterAgeLimitInDays, 'days').toISOString()
        }

        if (params.pickupArea) {
          reqParams.areaIdAtPickup = params.pickupArea
        }

        if (params.deliveriesType && params.deliveriesType !== 'all') {
          reqParams.direction = params.deliveriesType
        }

        if (params.dropoffArea) {
          reqParams.areaIdAtDropOff = params.dropoffArea
        }

        // lazy loading
        if (params.skip > 0) {
          reqParams.skip = params.skip
          delete reqParams.createdAfter
          delete reqParams.statusUpdatedAfter
        }

        statusPromises.push(kanbanService.get([], reqParams, true))
      })

      // we return flat list of all deliveries
      return Promise.all(statusPromises)
        .then(deliveriesByStatus => {
          let allDeliveries = []
          deliveriesByStatus.forEach(deliveries => allDeliveries = allDeliveries.concat(deliveries))
          return allDeliveries
        })
    },
    getAvailableDriversToTransfer,
    transferDeliveryToDriver,
    transferDeliveriesToDriver,
    getNextDayLinkedDeliveries,
    transferNextDayDeliveriesToDriver,
    getScheduled
  }
}

export default deliveriesCollectionFactory()
