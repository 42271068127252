const DEGREES_TO_RADIANS_CONST = 180

class Interval {
  constructor (
    public startX: number,
    public startY: number,
    public endX: number,
    public endY: number
  ) {}
}

export class Point {
  constructor (public x: number, public y: number) {}
}

export class MathUtils {

  static getPolygonVectors (polygon: Point[]): Interval[] {
    const polygonVectors: Interval[] = []

    for (let i = 1; i < polygon.length; i++) {
      const interval: Interval = new Interval(polygon[i - 1].x, polygon[i - 1].y, polygon[i].x, polygon[i].y)
      polygonVectors.push(interval)
      if (i === polygon.length - 1) {
        polygonVectors.push(new Interval(polygon[i].x, polygon[i].y, polygon[0].x, polygon[0].y))
      }
    }

    return polygonVectors
  }

  static isPolygonSelfIntersecting (polygon: Point[]): boolean {
    const polygonVectors: Interval[] = MathUtils.getPolygonVectors(polygon)
    let result = false
    for (const vector of polygonVectors) {
      for (const testVector of polygonVectors) {
        result = MathUtils.isIntersect(vector, testVector)
        if (result) {
          break
        }
      }
      if (result) {
        break
      }
    }

    return result
  }

  static isIntersect (intervalA: Interval, intervalB: Interval): boolean {
    const p1: Point = new Point(intervalA.startX, intervalA.startY)
    const p2: Point = new Point(intervalA.endX, intervalA.endY)
    const p3: Point = new Point(intervalB.startX, intervalB.startY)
    const p4: Point = new Point(intervalB.endX, intervalB.endY)

    // if some points share same coordinates than assuming that they are siblings
    if (
        (p1.x === p3.x && p1.y === p3.y)
      || (p2.x === p3.x && p2.y === p4.y)
      || (p4.x === p1.x && p4.y === p1.y)
      || (p4.x === p2.x && p4.y === p2.y)
    ) {
      return false
    }

    const v1: number = MathUtils.vectorMultiplication((p4.x - p3.x), (p4.y - p3.y), (p1.x - p3.x), (p1.y - p3.y))
    const v2: number = MathUtils.vectorMultiplication((p4.x - p3.x), (p4.y - p3.y), (p2.x - p3.x), (p2.y - p3.y))
    const v3: number = MathUtils.vectorMultiplication((p2.x - p1.x), (p2.y - p1.y), (p3.x - p1.x), (p3.y - p1.y))
    const v4: number = MathUtils.vectorMultiplication((p2.x - p1.x), (p2.y - p1.y), (p4.x - p1.x), (p4.y - p1.y))

    return v1 * v2 < 0 && v3 * v4 < 0
  }

  static isPolygonClockwise(polygon: Point[]): boolean {
    const polygonVectors: Interval[] = MathUtils.getPolygonVectors(polygon)
    let sum = 0
    for (const vector of polygonVectors) {
      sum += (vector.endX - vector.startX) * (vector.endY - vector.startY)
    }

    return sum > 0 ? true : false
  }

  static vectorMultiplication (ax: number, ay: number, bx: number, by: number): number {
    return ax * by - bx * ay
  }

  static ceilToStep (number: number, step: number): number {
    return Math.ceil(number / step) * step
  }

  static degreesToRadians (degrees: number): number {
    return degrees * Math.PI / DEGREES_TO_RADIANS_CONST
  }

  static radiansToDegrees (radians: number): number {
    return radians * DEGREES_TO_RADIANS_CONST / Math.PI
  }

  static floorToStep (number: number, step: number): number {
    return Math.floor(number / step) * step
  }

  static getIntervalLengthByPoints(startPoint: Point, endPoint: Point): number {
    return Math.sqrt(Math.pow((endPoint.x - endPoint.y), 2) + Math.pow((endPoint.y - startPoint.y), 2))
  }
}
