import AddressFieldDefinition from 'data/domain-objects/AddressFieldDefinition'

export default class AddressField {

  public fieldDefinition: AddressFieldDefinition
  public hint: string // this field is only for backward capability. Should be deleted when dynamic-address component is replaces by address editor
  public originalValue: string // this field is only useful for match-indicator component on requests page
  public languageCode: string

  constructor(
    public formatFieldId: string = '',
    public readonly name: string = '',
    public value: string = '',
    originalValue?: string
  ) {
    if (originalValue) {
      this.originalValue = originalValue
    }
  }

  public static build(dto: any = {}): AddressField {
    return new AddressField(
      dto.formatFieldId,
      dto.name,
      dto.value || '',
      dto.originalValue
    )
  }

  toJSON (): any {
    return {
      ...this,
      value: this.value.length ? this.value : null,

      fieldDefinition: undefined,
      hint: undefined
    }
  }
}
